import { Layout } from "antd"
import styled from "styled-components"

import { theme } from "@project/shared"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { AuthContext } from "../../../utils"
const { Footer: AntFooter } = Layout
const FooterStyled = styled(AntFooter)`
  min-height: 50px;
  background: #ffffff;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 70px 50px 90px 50px;
  flex-wrap: wrap;
  @media (max-width: 500px) {
    align-items: center;
    justify-content: center;
  }
  @media print {
    display: none;
  }
`
const LeftSide = styled.div`
  display: flex;
  img {
    height: fit-content;
    max-height: 190px;
    max-width: 190px;
    object-fit: contain;
  }
  .footer__leftSoide-info {
    /* background: red; */
  }
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;

  /* text-align: right; */
  @media (max-width: 500px) {
    margin-left: 0;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`

const Text = styled.div`
  font-weight: 500;
  font-size: 14px;
  word-break: break-all;
  white-space: pre-wrap;

  .no-break {
    white-space: nowrap;
  }
`
const FacilityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  border-left: 2px solid ${theme.gray2};
  margin-top: 10px;
  gap: 1.5em;
  padding-left: 0.5em;
  .name {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .phone,
  .fax {
    span {
      font-weight: bold;
    }
  }
`

const Footer = () => {
  const { facilities, childInformation, companyInfo } = useContext(AuthContext)
  const validFooterFacility =
    childInformation?.my_page_setting?.footer_information?.map(
      (facilityId) => +facilityId
    ) || []

  const showValidFooterInfo =
    facilities?.filter(
      (v) =>
        +childInformation?.active_contract_facility?.facility_id === +v.id &&
        validFooterFacility?.includes(+v.id)
    ) || []

  const { t } = useTranslation()
  return (
    <FooterStyled>
      <LeftSide>
        {childInformation?.my_page_setting?.logo_image ? (
          <img
            src={childInformation?.my_page_setting?.logo_image}
            alt={t("logo")}
          />
        ) : (
          <img src={"/assets/icons/logo.png"} alt={t("logo")} />
        )}

        {
          <div className={"footer__leftSide-info"}>
            {companyInfo &&
              childInformation?.my_page_setting?.footer_company_info_flg && (
                <TextWrapper>
                  <Text style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    {companyInfo?.company_name}
                  </Text>
                  <Text>
                    {`〒 ${companyInfo?.zip_code} ${companyInfo?.address}\nTEL.${companyInfo?.tel_no} / FAX.${companyInfo?.fax_no}`}
                  </Text>
                </TextWrapper>
              )}

            {childInformation?.my_page_setting?.footer_information?.length >
            0 ? (
              childInformation?.active_contract_facility?.facility
                ?.show_my_page_info === true ? (
                <FacilityWrapper>
                  {showValidFooterInfo.length > 0 &&
                    showValidFooterInfo?.map((facility) => (
                      <div key={facility?.id} className={"footer__facility"}>
                        <Text className={"name"}>
                          {facility?.facility_name}
                        </Text>
                        <Text className={"address"}>{facility?.address}</Text>
                        {facility?.phone_no && (
                          <div className={"phone"}>
                            <span>{t("TEL")}</span> {":"} {facility?.phone_no}
                          </div>
                        )}
                        {facility?.fax_no && (
                          <div className={"fax"}>
                            <span>{t("Fax")}</span> {":"} {facility?.fax_no}
                          </div>
                        )}
                      </div>
                    ))}
                </FacilityWrapper>
              ) : (
                <FacilityWrapper>
                  <div className={"footer__facility"}>
                    <Text className={"name"}>
                      {
                        childInformation?.active_contract_facility?.facility
                          ?.facility_name
                      }
                    </Text>
                  </div>
                </FacilityWrapper>
              )
            ) : (
              <></>
            )}
          </div>
        }
      </LeftSide>

      <div
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        {"Copyright "}
        {"©"}
        {" Onelife.Inc"}
      </div>
    </FooterStyled>
  )
}

export { Footer }
