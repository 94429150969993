import { RadioButtons, Required } from "@project/shared"
import React, { useEffect, useState } from "react"
import TextArea from "antd/lib/input/TextArea"
import { Wrapper } from "./EvaluationQuestion.styles"
import { useTranslation } from "react-i18next"

interface iEvaluationQuestion {
  index: number
  question: string
  defaultAnswer?: string
  setAnswer: React.Dispatch<any>
  required?: boolean
  defaultOpinion?: string
  options: string[]
  questionId: number | string
  handleSubmitClicked?: boolean
}

export const EvaluationQuestion = ({
  index,
  question,
  questionId,
  defaultAnswer = null,
  setAnswer,
  required,
  defaultOpinion = "",
  options,
  handleSubmitClicked,
}: iEvaluationQuestion) => {
  const { t } = useTranslation()
  const [tempAnswer, setTempAnswer] = useState(defaultAnswer)
  const [opinion, setOpinion] = useState(defaultOpinion)

  useEffect(() => {
    setAnswer((prev) => ({
      ...prev,
      [questionId]: {
        answer: tempAnswer,
        opinion,
      },
    }))
  }, [tempAnswer, opinion])

  return (
    <Wrapper id={String(questionId)}>
      <div className={"question-box"}>
        <div className={"index"}>{`Q${index}`}</div>
        <div className={"question border-bottom"}>
          <div>{question}</div>
          {required && <Required />}
        </div>
      </div>
      <div className={"answers-box"}>
        <RadioButtons
          options={options?.map((option) => ({ label: option, value: option }))}
          value={tempAnswer}
          onChange={(e) => setTempAnswer(e.target.value)}
          error={!tempAnswer && handleSubmitClicked && t("Required")}
        />
        <TextArea
          value={opinion}
          onChange={(e) => setOpinion(e.target.value)}
        />
      </div>
    </Wrapper>
  )
}
