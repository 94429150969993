import { CHILD_SERVICE_TYPES, theme } from "@project/shared"
import { Skeleton } from "antd"
import { t } from "i18next"
import React from "react"
import styled from "styled-components"
import wareki from "wareki"

const Wrapper = styled.div`
  margin-bottom: 10px;
  overflow: auto;
  @media print {
    margin-bottom: 0px;
  }
`
const WrapperTitle = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 580px) {
    margin-top: 30px;
    flex-wrap: wrap;
    .no-content {
      display: none;
    }
    .child-service-content {
      text-align: left;
    }
    flex-direction: column;
  }
`

const DateComp = styled.div`
  font-weight: 400;
  font-size: 12px;
  @media (max-width: 580px) {
    bottom: 20px;
  }
  @media print {
    font-size: 8px;
    margin-top: 5px;
    margin-left: 5px;
  }
`
const ServiceComp = styled.div`
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  @media (max-width: 580px) {
    margin-top: 5px;
  }
`

const Row1 = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 3fr 2fr 2fr 3fr;
  border: 0.5px solid #d2d1d1;
  min-width: 1200px;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    min-width: 550px;
  }
  @media print {
    min-width: 0px;
    width: 100%;
    border: none;
  }
`
const Row2 = styled(Row1)`
  grid-template-columns: 2fr 8fr 2fr 3fr;
  margin-top: -0.5px;
  min-width: 1200px;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    min-width: 550px;
  }
  @media print {
    min-width: 0px;
    width: 100%;
  }
`
const Title = styled.div`
  background-color: #fafafa;
  border: 0.5px solid #d2d1d1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  white-space: break-spaces;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    font-size: 12px;
    padding: 4px 0;
  }
  @media print {
    font-size: 10px;
  }
`

const Answer = styled.div`
  border: 0.5px solid #d2d1d1;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  .bordered-number {
    border-left: 0.5px solid #d2d1d1;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child {
      @media print {
        border-left: none;
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    font-size: 12px;
    .bordered-number {
      font-size: 12px;
    }
  }
  @media print {
    font-size: 10px;
    .bordered-number {
      font-size: 10px;
    }
  }
`
const TextAnswer = styled.div`
  padding: 10px;
  border: 0.5px solid #d2d1d1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    font-size: 12px;
    padding: 4px 0;
  }
  @media print {
    font-size: 10px;
  }
`

const ServiceProvisionTopComponent: React.FC<{
  data?: any
  facilityName?: string
  isLoadingInfo?: boolean
  className?: string
  year?: number
  month?: number
}> = ({ data, isLoadingInfo, className, year, month, facilityName }) => {
  const certificateNumber =
    data?.child_receiving_certificates[0]?.receiving_certificate_number || "0"
  const officeNumber =
    data?.active_contract_facility?.facility?.business_number || "0"
  const era =
    `${wareki(`${year}-${month?.toString()?.padStart(2, "0")}`, {
      unit: true,
    })}` +
    month?.toString()?.padStart(2, "0") +
    "月分"
  if (isLoadingInfo) {
    return (
      <Wrapper>
        <Skeleton />
      </Wrapper>
    )
  }
  return (
    <>
      <WrapperTitle>
        <>
          <DateComp>{era}</DateComp>
          <ServiceComp className={"child-service-content"}>
            {
              CHILD_SERVICE_TYPES[
                data?.child_receiving_certificates[0]?.service_type
              ]
            }
          </ServiceComp>
          <ServiceComp className={"no-content"}></ServiceComp>
        </>
      </WrapperTitle>
      <Wrapper className={className}>
        <Row1>
          <Title>{t("Benificiary Certificate Number")}</Title>
          <Answer>
            {certificateNumber
              .padStart(10, "0")
              .split("")
              .map((item, index) => (
                <div key={index} className={"bordered-number"}>
                  {item}
                </div>
              ))}
          </Answer>
          <Title>
            {t(
              "Name of guardian for whom benefits were determined\n (Child name)"
            )}
          </Title>
          <TextAnswer>
            {data?.Parent?.parent_name || "-"}
            <br />
            {data?.child_name ? `(${data?.child_name})` : ""}
          </TextAnswer>
          <Title>{t("Office Number")}</Title>
          <Answer>
            {officeNumber
              .padStart(10, "0")
              .split("")
              .map((item, index) => (
                <div key={index} className={"bordered-number"}>
                  {item}
                </div>
              ))}
          </Answer>
        </Row1>
        <Row2>
          <Title>{t("Contract Number")}</Title>
          <TextAnswer>
            {data?.contact_benefit_days
              ? `${data?.contact_benefit_days}日`
              : "-"}
          </TextAnswer>
          <Title>{t("Business operator and its place of business")}</Title>
          <TextAnswer>{facilityName}</TextAnswer>
        </Row2>
      </Wrapper>
    </>
  )
}

export { ServiceProvisionTopComponent }
