import React from "react"
import {
  Table as SharedTable,
  Pagination as SharedPagination,
  theme,
} from "@project/shared"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { ChildButton } from "../../atoms/ChildButton"

interface ITablecomponentProps {
  dataSource: any
  columns: any
  total?: number
  pageSize?: number
  currentPage?: number
  handlePaginationChange?: (a: number) => void
  loading?: boolean
  scroll?: { x?: string | number | true } | Record<string, never>
  bordered?: boolean
  displayPrintButton?: boolean
  buttonText?: string
  additionalButton?: boolean
  onClickAdditionalButton?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void
  furiganaSelect?: string
  setFurignaValue?: (string) => void
  itemListFiltered?: any
  itemValue?: any
  setItemSelect?: (string) => void
  fetchTableData?: any
  showCollectionRate?: boolean
  rowClassName?: any
  answeredCount?: number
  topComponent?: JSX.Element
}

const Wrapper = styled.div<ITablecomponentProps>`
  .main-pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: "center";
    padding: 0px 5px;
    @media (max-width: 767px) {
      justify-content: center;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .bottom-pagination-wrapper {
    margin-top: 10px;
    padding: 0px;
    align-items: center;
    @media (max-width: 767px) {
      align-items: flex-end;
    }
  }
  .info-wrapper {
    display: flex;
    flex-direction: column;
  }
  .add-btn-wrap {
    margin-bottom: 20px;
  }
  .stat-container {
    display: flex;
    align-items: center;
    margin: 8px 20px 8px 0;
    flex-wrap: wrap;
  }
  .stat-label {
    position: relative;
    padding: 8px 2px 8px 8px;
    background: #cde6f4;
    margin-right: 20px;
  }
  .stat-label::after {
    position: absolute;
    top: 0;
    right: -20px;
    content: "";
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 10px solid #cde6f4;
    height: 100%;
    width: 20px;
  }
  @media (max-width: 768px) {
    display: block;
    .stat-container {
      margin-right: 0;
    }
  }
  @media print {
    .bottom-pagination-wrapper,
    .main-pagination-wrapper {
      display: none;
    }
  }
`
const TableWrapper = styled.div`
  @media print {
    .ant-table-container table {
      th,
      td {
        font-size: 10px;
        padding: 2px;
        div,
        span,
        p {
          font-size: 10px;
        }
      }
    }

    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th {
      border: 0.5px solid #d2d1d1 !important;
    }

    table tbody tr {
      page-break-inside: avoid;
    }
  }

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    .ant-table-container table {
      th,
      td {
        font-size: 12px;
        div,
        span,
        p {
          font-size: 12px;
        }
      }
    }
  }
`
const Tablecomponent: React.FC<ITablecomponentProps> = (props) => {
  const { t } = useTranslation()
  const {
    dataSource,
    columns,
    total,
    pageSize,
    currentPage,
    loading,
    scroll,
    bordered,
    handlePaginationChange,
    buttonText,
    additionalButton,
    onClickAdditionalButton,
    showCollectionRate,
    rowClassName = "",
    answeredCount,
    topComponent,
  } = props
  return (
    <Wrapper {...props}>
      {total && currentPage && pageSize ? (
        <>
          <div className={"main-pagination-wrapper"}>
            <div className={"info-wrapper"}>
              <div>
                {showCollectionRate && total > 0 ? (
                  <div className={"stat-container"}>
                    <span className={"stat-label"}>
                      {t("児発 回収数（回収率）")}
                    </span>
                    <span>{`${answeredCount}/${total} (${
                      (answeredCount * 100) / total
                    }%)`}</span>
                  </div>
                ) : (
                  <p>{t("Total {{total}} datas", { total })}</p>
                )}
              </div>
            </div>
            <div>
              <SharedPagination
                total={total}
                current={currentPage}
                pageSize={pageSize}
                onChange={handlePaginationChange}
              />
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div>
        {additionalButton && (
          <div className={"add-btn-wrap"}>
            <ChildButton
              typeOf={"primary"}
              text={
                buttonText ||
                t("Service provision record/Care record/Life record")
              }
              onClick={onClickAdditionalButton}
            />
          </div>
        )}
        {!!topComponent && topComponent}
        <TableWrapper>
          <SharedTable
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            scroll={scroll || {}}
            bordered={bordered ? true : false}
            rowClassName={rowClassName}
          />
        </TableWrapper>
      </div>
      {total && currentPage && pageSize ? (
        <div className={"main-pagination-wrapper bottom-pagination-wrapper"}>
          <div>
            <p>{t("Total {{total}} datas", { total })}</p>
          </div>
          <div>
            <SharedPagination
              total={total}
              current={currentPage}
              pageSize={pageSize}
              onChange={handlePaginationChange}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </Wrapper>
  )
}

export { Tablecomponent }
