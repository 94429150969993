import { theme } from "@project/shared"
import styled from "styled-components"

export const Wrapper = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 20px;
  .bordered {
    border: 1px solid #d2d1d1;
  }
  .border-bottom {
    border-bottom: 1px solid #d2d1d1;
  }
  .question-box {
    display: flex;
    width: 100%;
    .index,
    .question {
      padding: 0.56rem 1.37rem;
      div {
        color: ${theme.bgOrange};
      }
      [class*="Required"] {
        font-size: 12px;
      }
    }
    .index {
      background: #f3f3f3;
      border: 1px solid #d2d1d1;
      border-top: 0px;
      border-left: 0px;
    }
    .question {
      display: flex;
      flex: 1;
      column-gap: 1.63rem;
      row-gap: 10px;
      align-items: center;
      justify-content: space-between;
      @media (max-width: ${theme.breakpoints.xs}) {
        column-gap: 1rem;
      }
    }
  }
  .answers-box {
    padding: 1rem 1.37rem;
    .opinion {
      word-wrap: break-word;
    }
    textarea {
      margin-top: 1.6rem;
      border-radius: 5px;
    }
  }
  .radio-group > p:first-of-type {
    font-size: 12px;
    color: rgb(235, 87, 87);
    margin-left: 2px;
    height: 20px;
  }
`
