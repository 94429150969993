import { theme } from "@project/shared"
import styled, { css } from "styled-components"
interface DetailCommentAndImageContainerProps {
  column?: string
  gap?: string
}

const Grid = css`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
`

export const DetailWrapper = styled.div`
  border: 1px solid ${theme.gray2};
  background-color: ${theme.base};
  min-height: 60vh;
  border-radius: 4px;
  margin-top: 1rem;
`
export const DetailsTopContainer = styled.div`
  background: #f3f3f3;
  padding: 0.5rem 1rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid ${theme.gray2};
  display: flex;
  align-items: center;
  .top__title-tag {
    background-color: #5171ea;
    border-radius: 0.4rem;
    color: ${theme.base};
    font-weight: bold;
    padding: 0.2rem 0.5rem;
  }

  .top__title-text {
    margin: 0;
    font-weight: bold;
    color: ${theme.red3};
    font-size: 18px;
  }
`

export const DetailCommentAndImageContainer = styled.div<DetailCommentAndImageContainerProps>`
  display: grid;
  grid-template-columns: ${(props) => props?.column || "1.5fr 1fr"};
  width: 100%;
  gap: ${(props) => (props?.gap ? "1rem" : "0rem")};
  .comment__situation__container {
    display: grid;
    grid-template-columns: repeat(1, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  .details__images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 0.8rem;
    height: fit-content;
    position: relative;

    .image__container {
      position: relative;
      .download_icon_container {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        transition: all 0.3s ease-in;
        svg {
          font-size: 30px;
          font-weight: bold;
          color: white;
          background-color: ${theme.blue7};
          border-radius: 50%;
          padding: 5px;
        }
      }
      &:hover .download_icon_container {
        visibility: visible;
        cursor: pointer;
      }
      .image {
        height: 195px;
        min-width: 200px;
        max-width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        position: relative;
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.xmd}) {
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  }
`
export const DetailsContentContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
  padding: 1rem;

  .basic__info {
    ${Grid}
    .signature_content {
      display: flex;
      justify-content: flex-end;
      > div > div > img {
        margin-right: 12px;
      }
    }
    .sign_container {
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }
  .basic__info .grid-header {
    white-space: nowrap;
    width: 16rem;
  }
  .sign-img {
    height: auto;
    max-height: 26px;
    max-width: 163px;
  }
`
export const DetailSituationContainer = styled.div`
  border: 1px solid ${theme.gray2};
  border-radius: 6px;
  min-height: 180px;
  .situation__top {
    background: #f3f3f3;
    padding: 0.5rem 1rem;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: 1px solid ${theme.gray2};
    text-align: center;
  }
  .situation__comments {
    padding: 0.5rem 1rem;
    word-wrap: break-word;
    white-space: break-spaces;
  }
`
export const ProvisionDetailContainer = styled.div`
  .provision__top {
    background: #f3f3f3;
    padding: 0.5rem 1rem;
    border: 1px solid ${theme.gray2};
    border-bottom: 0;
    text-align: center;
  }
  .provision__content {
    ${Grid}

    .items {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

      p {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
`
export const RecorderContainer = styled.div`
  ${Grid}
  .status__btn {
    border-radius: 8px;
  }
  .status__btn-red {
    background-color: ${theme.red1};
    color: ${theme.base} !important;
    .icon-red {
      color: ${theme.red1} !important;
    }
  }
  .status__btn-green {
    border: 1px solid ${theme.green1} !important;
    color: ${theme.green1} !important;
    .icon-green {
      color: ${theme.green1} !important;
    }
  }
`

//loader style

const LTop = css`
  background: #f3f3f3;
  padding: 0.5rem 1rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid ${theme.gray2};
  text-align: center;
`
const LContent = css`
  display: flex;
  gap: 0.5rem;
`
export const DetailLoaderContainer = styled.div`
  .images__loader {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10rem;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .loader__content {
      ${LContent}
    }
  }
  .situation__loader {
    .situation__loader__top {
      ${LTop}
    }
  }
  .provision__loader {
    .provision__loader__top {
      ${LTop}
    }
    .loader__content {
      border: 1px solid ${theme.gray2};
      border-top: 0;
      ${LContent}
    }
  }
  .loader__recorder {
    ${LContent}
  }
`
