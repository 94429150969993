import { FileUploader, TextField } from "@project/shared"
import { t } from "i18next"
import React, { useState } from "react"
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
} from "react-query"
import styled from "styled-components"
import { addChat } from "../../../services"
import { ChildButton } from "../../atoms/ChildButton"

interface iSendMessage {
  setData?: React.Dispatch<
    React.SetStateAction<
      (
        | {
            date: string
            message: string
            self: boolean
            read?: undefined
          }
        | {
            date: string
            message: string
            self: boolean
            read: boolean
          }
      )[]
    >
  >
  id?: string
  data?: any
  refetch?: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<
    QueryObserverResult<
      {
        data: any
        count: number
      },
      unknown
    >
  >
}

const Container = styled.div`
  margin: 50px -30px -27px -30px;
  padding: 30px;
  background: #f3f3f3;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-top: 1px solid #0782c8;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`

const TextWrapper = styled.div`
  margin-top: 22px;
  textarea {
    background-color: #ffffff !important;
  }
`
const BottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .warn {
    margin-top: 18px;
    display: flex;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 18px;
  button {
    height: 50px;
  }
`
const FileUploadWrapper = styled.div`
  margin-top: 22px;
`

const SendMessageComponent: React.FC<iSendMessage> = ({ refetch }) => {
  const [conveyMessage, setConveyMessage] = useState("")
  const [type, setType] = useState("text")
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [isUploadLoading, setIsUploadLoading] = useState(false)

  const { mutate, isLoading: isMutuating } = useMutation(addChat, {
    onSuccess: () => {
      refetch()
      resetForm()
    },
  })
  const resetForm = () => {
    setConveyMessage("")
    setFile(null)
    setFileName(null)
    setType("text")
  }
  const handleSubmitData = () => {
    if (type === "text" && !conveyMessage) return
    if (type === "file" && !file) return

    const SendData = {
      parent_comment: conveyMessage,
      file_url: file,
      file_name: fileName,
      type: type,
    }
    mutate(SendData)
  }

  return (
    <Container>
      <div className={"notes"}>
        {t(
          "※Some pictograms and machine-dependent characters (environment-dependent characters) cannot be used."
        )}
      </div>
      <div className={"notes"}>
        {t(
          "※Files can be selected from jpg, png, gif, HEIC, pdf, docx, and xlsx."
        )}
      </div>
      <FileUploadWrapper>
        <FileUploader
          currentImage={file}
          onChange={(val) => setFile(val)}
          setFileName={setFileName}
          fileName={fileName}
          setType={setType}
          isLoading={setIsUploadLoading}
          allowAllFileTypes={false}
        />
      </FileUploadWrapper>
      <TextWrapper>
        <TextField
          name={"convey-parent"}
          value={conveyMessage}
          onChange={(evt) => setConveyMessage(evt.target.value)}
          type={"textarea"}
          placeholder={t(
            "Please enter the information you would like to convey to facility."
          )}
          rows={9}
        />
      </TextWrapper>

      <BottomSection>
        <ButtonWrapper>
          <ChildButton
            typeOf={"primary"}
            text={t("Send")}
            disabled={
              (type === "text" && !conveyMessage) ||
              (type === "file" && !file) ||
              isMutuating ||
              isUploadLoading
            }
            isLoading={isMutuating}
            onClick={handleSubmitData}
          />
        </ButtonWrapper>
      </BottomSection>
    </Container>
  )
}

export { SendMessageComponent }
