import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query"
import styled from "styled-components"
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
//components imports
import {
  SelectInput,
  Checkbox,
  REMARK_OPTION,
  RadioButtons,
  ACTUAL_EXPENSE_OPTIONS,
  theme,
} from "@project/shared"
import { Button } from "antd"
import { IReturnServiceProvisionSheet } from "../../../services"
import { Title } from "../../../utils/shared-style"
import { ChildButton } from "../../atoms/ChildButton"
import { StyledMonthPaginationWrapper } from "../CalanderListComponent"
import router from "next/router"
import { DatePicker } from "../../molecules"
import moment from "moment"

interface iServiceSheetOperationComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  marginBottom?: string
  handleSearch?: (value: string) => void
  handleSearchReset?: () => void
  setQueryParam?: React.Dispatch<
    React.SetStateAction<{
      year: string
      month: string
      facilityId: any
      showAbsence: any
      examContent: any[]
      expense: number
    }>
  >
  queryParam?: {
    year: any
    month: any
    facilityId: any
    showAbsence: any
    examContent: any[]
    expense: number
  }
  refetch?: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<IReturnServiceProvisionSheet, unknown>>
  isLoadingData?: boolean
  year?: any
  month?: any
  handleDisplayChange?: ({
    year,
    month,
    ...rest
  }: {
    year: any
    month: any
    [x: string]: any
  }) => void
  FACILITY_OPTION?: {
    value: string
    label: string
  }[]
  isFetchingInfo?: boolean
}

const Wrapper = styled.div<iServiceSheetOperationComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 36px;
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .search-btn {
    margin-left: 0;

    @media screen and (max-width: 380px) {
      font-size: 13px;
    }
  }
  .reset-button {
    margin-left: 9.5px;
  }
  .select-down {
    padding-left: 9px;
  }
  .select-top {
    padding-left: 9px;
    padding-right: 10px;
  }
  .title-wrapper {
    min-width: 185px;
  }
  .inline-field-wrapper {
    align-items: flex-start;
  }
  @media print {
    display: none;
  }

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    padding: 12px;
  }
`
const DateSelectWrap = styled.div`
  display: flex;
  align-items: center;
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .text-field {
    width: 100%;
  }

  input {
    background-color: #ffffff;
    width: 712px;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }

  .show-absence-checkbox {
    white-space: normal;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;

  .ant-checkbox-wrapper {
    display: flex;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .ant-checkbox-group {
    margin-left: 0;
  }
  &.exam-content-options {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    label {
      white-space: normal;
    }
    .expense-options {
      margin-left: 30px;
    }
  }
`
const ButtomComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  flex-wrap: wrap;

  @media (max-width: 820px) {
    flex-direction: column;
    align-items: flex-start;
    & > :first-child {
      margin-bottom: 40px;
    }
  }
  .month-pagination {
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: flex-start;
  }
  @media (max-width: ${theme.breakpoints.xxs}) {
    .month-pagination {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      width: 100%;
      button {
        margin-right: 0;
        padding: 0px;
        min-width: 0;
        width: 100%;
      }
    }
  }
  @media (max-width: 380px) {
    align-items: center;
    justify-content: center;
  }
`
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`
const Buttonwrapper = styled.div`
  display: flex;
  align-items: center;
  button {
    padding-inline: 30px;
  }
  & > :first-child {
    margin-right: 10px;
  }
  @media (max-width: 380px) {
    align-items: center;
    justify-content: center;
  }
`
const Text = styled(Title)`
  font-size: 16px;
`

const ServiceSheetOperationComponent: React.FC<
  iServiceSheetOperationComponent
> = (props) => {
  const {
    header,
    month,
    year,
    handleDisplayChange,
    isLoadingData,
    FACILITY_OPTION,
    queryParam,
    isFetchingInfo,
  } = props
  const { t } = useTranslation()

  const [facilityId, setFacilityId] = useState(queryParam?.facilityId)
  const [showAbsence, setShowAbsence] = useState(null)
  const [contractYear, setContractYear] = useState(queryParam.year)
  const [contractMonth, setContractMonth] = useState(queryParam.month)
  const [remark, setRemark] = useState(queryParam?.examContent)
  const [expense, setExpense] = useState(queryParam?.expense)
  const handleSearch = () => {
    handleDisplayChange({
      year: contractYear,
      month: contractMonth,
      facilityId: facilityId,
      showAbsence: showAbsence,
      examContent: remark,
      expense,
    })
  }

  const handleRemarkChange = (value, type: "add" | "remove") => {
    let newRemarks = Array.isArray(remark) ? [...remark] : []
    if (type == "add") {
      newRemarks.push(value)
    } else {
      newRemarks = newRemarks.filter((r) => r != value)
    }
    setRemark(newRemarks)
  }

  const handlereset = () => {
    const today = new Date()
    const today_month = today.getMonth() + 1
    const today_year = today.getFullYear()
    setFacilityId(FACILITY_OPTION ? FACILITY_OPTION[0]?.value : null)
    setShowAbsence(null)
    setContractYear(today_year)
    setContractMonth(today_month)
    setRemark(null)
    handleDisplayChange({
      year: today_year,
      month: today_month,
      facilityId:
        router?.query?.facilityId ?? FACILITY_OPTION
          ? FACILITY_OPTION[0]?.value
          : null,
      showAbsence: null,
      examContent: null,
    })
  }
  useEffect(() => {
    setFacilityId(FACILITY_OPTION ? FACILITY_OPTION[0]?.value : null)
  }, [isFetchingInfo])

  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <FieldWrapper>
        <TitleWrap className={"title-wrapper"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Institution")} </Text>
        </TitleWrap>
        <DateSelectWrap>
          <SelectInput
            width={150}
            height={40}
            key={"footerInformation"}
            name={"footerInformation"}
            value={facilityId}
            className={"select-up"}
            onChange={(values) => {
              setFacilityId(values)
            }}
            options={FACILITY_OPTION}
            placeholder={t("All")}
            loading={isFetchingInfo}
          />
        </DateSelectWrap>
      </FieldWrapper>

      <FieldWrapper>
        <TitleWrap className={"title-wrapper"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Year month")} </Text>
        </TitleWrap>
        <SelectWrap className={"institution-dropdowns-wrapper"}>
          <DatePicker
            date={moment({ year: contractYear, month: contractMonth - 1 })}
            style={{
              height: "40px",
              width: "150px",
            }}
            picker={"month"}
            format={"YYYY年MM月"}
            showArrow={true}
            disabledDate={(current) => {
              return (
                (current && current < moment("2021/01/01")) ||
                (current && current > moment().add(5, "year"))
              )
            }}
            onDateChange={(value) => {
              setContractYear(moment(value).format("YYYY"))
              setContractMonth(moment(value).format("M"))
            }}
          />
        </SelectWrap>
      </FieldWrapper>

      <FieldWrapper className={"inline-field-wrapper"}>
        <TitleWrap className={"title-wrapper"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Contents of remarks")} </Text>
        </TitleWrap>
        <SelectWrap className={"exam-content-options"}>
          {REMARK_OPTION.map((remarkOption) => {
            return (
              <>
                <Checkbox
                  key={remarkOption.value}
                  checked={
                    Array.isArray(remark) &&
                    remark?.includes(remarkOption.value)
                  }
                  value={remarkOption.value}
                  label={remarkOption.label}
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleRemarkChange(e.target.value, "add")
                    } else {
                      handleRemarkChange(e.target.value, "remove")
                    }
                  }}
                />
                {remarkOption.value == "2" &&
                  Array.isArray(remark) &&
                  remark.includes(remarkOption.value) && (
                    <div className={"expense-options"}>
                      <RadioButtons
                        value={expense}
                        options={ACTUAL_EXPENSE_OPTIONS}
                        onChange={(e) => {
                          setExpense(+e.target.value)
                        }}
                      />
                    </div>
                  )}
              </>
            )
          })}
        </SelectWrap>
      </FieldWrapper>

      <FieldWrapper>
        <TitleWrap className={"title-wrapper"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Absence information")} </Text>
        </TitleWrap>

        <DateSelectWrap>
          <SelectWrap>
            <Checkbox
              key={"footerInformation"}
              name={"footerInformation"}
              checked={showAbsence}
              label={t(
                "Show absences (does not take extra credit for absences)"
              )}
              onChange={(evt) => {
                setShowAbsence(evt.target.checked ? "1" : null)
              }}
              className={"show-absence-checkbox"}
            />
          </SelectWrap>
        </DateSelectWrap>
      </FieldWrapper>

      <ButtomComponent>
        <Buttonwrapper>
          <ChildButton
            className={"search-btn"}
            text={t("Display change")}
            shape={"circle"}
            type={"submit"}
            typeOf={"primary"}
            onClick={() => handleSearch()}
            isLoading={isLoadingData}
          />
          <ChildButton
            className={"search-btn"}
            text={t("Reset")}
            shape={"circle"}
            typeOf={"secondary"}
            onClick={() => handlereset()}
          />
        </Buttonwrapper>

        <StyledMonthPaginationWrapper className={"month-pagination"}>
          <Button
            onClick={() => {
              let currentYear = year
              let currentMonth = month
              if (month == 1) {
                currentMonth = 12
                --currentYear
              } else {
                --currentMonth
              }
              setContractYear(currentYear)
              setContractMonth(currentMonth)
              handleDisplayChange({
                year: currentYear,
                month: currentMonth,
                facilityId: facilityId,
                showAbsence: showAbsence,
                examContent: remark,
                expense,
              })
            }}
            className={"left-btn"}
          >
            <DoubleLeftOutlined />
            <span className={"left-btn-label"}>{t("Last month")}</span>
          </Button>
          <Button
            className={"active active-btn"}
            onClick={() => {
              const today = new Date()
              const month = today.getMonth() + 1
              const year = today.getFullYear()
              setContractMonth(month)
              setContractYear(year)
              handleDisplayChange({
                year,
                month,
                facilityId: facilityId,
                showAbsence: showAbsence,
                examContent: remark,
                expense,
              })
            }}
          >
            {t("This month")}
          </Button>
          <Button
            onClick={() => {
              let currentYear = year
              let currentMonth = month
              if (currentMonth == 12) {
                ++currentYear
                currentMonth = 1
              } else {
                ++currentMonth
              }
              setContractMonth(currentMonth)
              setContractYear(currentYear)
              handleDisplayChange({
                year: currentYear,
                month: currentMonth,
                facilityId: facilityId,
                showAbsence: showAbsence,
                examContent: remark,
                expense,
              })
            }}
            className={"right-btn"}
          >
            <span className={"right-btn-label"}>{t("Next month")}</span>
            <DoubleRightOutlined />
          </Button>
        </StyledMonthPaginationWrapper>
      </ButtomComponent>
    </Wrapper>
  )
}

export { ServiceSheetOperationComponent }
