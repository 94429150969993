import React from "react"
import styled from "styled-components"

interface IDataCard {
  data: string
  title: string
  className?: string
}

const Containeer = styled.div`
  background: #ffffff;
  border: 1px solid #d9dee0;
  width: 212px;
  @media (max-width: 390px) {
    width: 100%;
  }
`
const Title = styled.div`
  background: #f1f4f5;
  text-align: center;
  font-size: 14px;
  height: 29px;
`
const Data = styled.div`
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 29px);
  width: 100%;
  word-break: break-all;
  padding: 14px;
`

const DataCard: React.FC<IDataCard> = ({ data, title, className }) => {
  return (
    <Containeer className={className}>
      <Title>{title}</Title>
      <Data>{data}</Data>
    </Containeer>
  )
}

export { DataCard }
