import React from "react"
import {
  Table as SharedTable,
  Pagination as SharedPagination,
} from "@project/shared"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

interface IOperationTableProps {
  dataSource: any
  columns: any
  total: number
  pageSize: number
  currentPage: number
  handlePaginationChange: (a: number) => void
  loading?: boolean
}

const Wrapper = styled.div`
  .main-pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px;
  }
  .bottom-pagination-wrapper {
    margin-top: 10px;
    padding: 0px;
  }
`

const OperationTable: React.FC<IOperationTableProps> = ({
  dataSource,
  columns,
  total,
  pageSize,
  currentPage,
  handlePaginationChange,
  loading = false,
}) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className={"main-pagination-wrapper"}>
        <div>
          <p>{t("Total {{total}} datas", { total })}</p>
        </div>
        <div>
          <SharedPagination
            total={total}
            current={currentPage}
            pageSize={pageSize}
            onChange={handlePaginationChange}
          />
        </div>
      </div>
      <div>
        <SharedTable
          dataSource={dataSource}
          columns={columns}
          loading={loading}
        />
      </div>
      <div className={"main-pagination-wrapper bottom-pagination-wrapper"}>
        <div>
          <p>{t("Total {{total}} datas", { total })}</p>
        </div>
        <div>
          <SharedPagination
            total={total}
            current={currentPage}
            pageSize={pageSize}
            onChange={handlePaginationChange}
          />
        </div>
      </div>
    </Wrapper>
  )
}

export { OperationTable }
