import { BreadCrumb, theme } from "@project/shared"
import Head from "next/head"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Container } from "../../../utils"
const Wrapper = styled(Container)`
  margin-top: 2rem;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${theme.base};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 2rem;
  margin-top: 20px;
  min-height: 50vh;
  h2 {
    font-weight: bold;
  }
`

export const AccessErrorPage = ({
  title,
  subTitle,
  hideHead,
  hideBreadcrumb,
}: {
  title: string
  subTitle?: string
  hideHead?: boolean
  hideBreadcrumb?: boolean
}) => {
  const { t } = useTranslation()
  const breadCrumbItems = [
    {
      key: "home",
      name: t("Home"),
      href: "/",
    },
    {
      key: "access-error",
      name: t("access error"),
    },
  ]

  return (
    <>
      {!hideHead ? (
        <Head>
          <title>
            {t("access error")}
            <title></title>
          </title>
        </Head>
      ) : (
        ""
      )}

      <Wrapper>
        {!hideBreadcrumb ? <BreadCrumb items={breadCrumbItems} /> : ""}
        <Content>
          <h2>{title}</h2>
          <h3>{subTitle || t("This screen is not currently published")}</h3>
        </Content>
      </Wrapper>
    </>
  )
}
