import React from "react"
import { StyledNotice } from "./index.styles"
import Image from "next/image"
import { Tag } from "antd"

interface iNotice {
  tag: string
  heading: string
  text: string
  onClick?: () => void
}

const AlertIcon = (
  <Image src={"/assets/icons/NotificationAlert.svg"} height={24} width={24} />
)
const RightArrow = (
  <Image src={"/assets/icons/arrow.svg"} height={5} width={5} />
)

const NoticeComponent = ({ tag, heading, text, onClick }: iNotice) => (
  <StyledNotice onClick={onClick}>
    <div className={"head"}>
      {AlertIcon}
      <Tag className={"tag"}>{tag}</Tag>
    </div>
    <div className={"contents"}>
      <div className={"body"}>
        <div className={"heading"}>{heading}</div>
        <div className={"text"}>{text}</div>
      </div>
      {RightArrow}
    </div>
  </StyledNotice>
)

export { NoticeComponent }
