import { theme } from "@project/shared"
import styled from "styled-components"
import Image from "next/image"
import { Card as AntCard } from "antd"

interface CardProps {
  title?: string
  content?: string
  borderColor?: string
  color?: string
  icon?: string
  children?: any
}

const StyledCard = styled(AntCard)<{ borderColor?: string; color?: string }>`
  width: 100%;
  border-radius: 12px;
  border: ${(props) => props.borderColor || `1px solid ${theme.red1}`};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  color: ${(props) => props.color || theme.red1};
  font-size: 18px;
  margin-top: 30px !important;

  @media screen and (max-width: 600px) {
    .contents > .title {
      font-size: 14px;
    }
    .contents > .content {
      font-size: 12px;
      padding-left: 30px;
    }
  }
  .ant-card-body {
    padding: 24px 30px !important;
    @media screen and (min-width: 768px) {
      white-space: pre;
    }
  }
  .contents {
    max-width: 38rem;
    color: ${theme.red1};
    .title {
      font-weight: bold;
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      .icon-wrapper {
        margin-right: 12px;
        display: flex;
        justify-content: center;
      }
      img {
        min-height: 20px !important;
        min-width: 20px !important;
      }
    }
  }
`

const Card = ({ title, content, icon, children }: CardProps) => {
  return (
    <StyledCard>
      <div className={"contents"}>
        <div className={"title"}>
          {icon ? (
            <div className={"icon-wrapper"}>
              <Image src={icon} width={20} height={20} alt={"icon"} />
            </div>
          ) : (
            ""
          )}
          {title}
        </div>
        <div className={"content"}>{content}</div>
      </div>
      {children}
    </StyledCard>
  )
}

export { Card }
