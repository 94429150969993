import { RightOutlined } from "@ant-design/icons"
import { TextField } from "@project/shared"
import { TextFieldProps } from "@project/shared/src/components/atom/TextField"
import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .label-wrapper {
    min-width: 240px;
    label {
      margin-left: 10px;
    }
    .icon {
      color: #d2d1d1;
    }
  }
  .text-field-wrapper {
    input {
      background: #ffffff;
      border: 1px solid #d2d1d1;
      border-radius: 5px;
      width: 300px;
      height: 40px;

      @media (max-width: 456px) {
        width: 100%;
      }
    }
  }
`

const InputWithArrowedLabel: React.FC<TextFieldProps> = (props) => {
  return (
    <Wrapper>
      <div className={"label-wrapper"}>
        <RightOutlined className={"icon"} />
        <label>{props.label}</label>
      </div>
      <div className={"text-field-wrapper"}>
        <TextField {...props} label={""} />
      </div>
    </Wrapper>
  )
}

export { InputWithArrowedLabel }
