import styled from "styled-components"

const Footer = styled.footer`
  display: flex;
  background: white;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  height: 80px;
  @media (max-width: 400px) {
    flex-direction: column;
    row-gap: 2px;
  }
  ul {
    display: flex;
    align-items: center;
    margin: auto;
    margin-left: 28px;

    @media (max-width: 767px) {
      margin: 0px;
      margin-left: 8px;
    }

    li {
      padding: 0px;
      list-style: none;
      margin-right: 45px;
      font-size: 16px;
      @media (max-width: 767px) {
        font-size: 10px;
        margin-right: 4px;
        padding-left: 15px;
      }
    }
  }
  .copyright-text {
    font-size: 12px;
    margin: auto;
    margin-right: 30px;
    @media (max-width: 580px) {
      margin-right: 12px;
    }
  }
`
const UnAuthorizedFooter = () => {
  return (
    <Footer>
      <div></div>
      <div>
        <p className={"copyright-text"}>{"Copyright © Onelife.Inc"}</p>
      </div>
    </Footer>
  )
}

export { UnAuthorizedFooter }
