import { theme } from "@project/shared"
import styled from "styled-components"

export const TitleWrapper = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 22px 30px 44px 32px;
  margin-bottom: 10px;
  margin-top: 10px;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    padding: 12px;
  }
`
export const Content = styled.div`
  font-weight: 400;
  font-size: 15px;
  .text-area {
    margin-top: 13px;
    margin-bottom: 18px;
    textarea {
      background-color: #ffffff;
    }
  }
`
export const Title = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ff8200;
`
export const GridStyle = styled.div`
  display: flex;
  margin-top: 18px;
  margin-bottom: 18px;
  gap: 20px;
  flex-wrap: wrap;

  @media screen and (max-width: 585px) {
    & > div {
      width: 100%;
    }
  }
`
export const Text = styled.div`
  font-size: 15px;
  margin-top: 14px;
  word-break: break-word;
  white-space: break-spaces;
`

export const SituationContainer = styled.div`
  padding: 0 0 40px 0;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.blueLight};
  font-size: 14px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  cursor: pointer;
  width: 100%;
  position: absolute;
  padding: 8px;
  bottom: 0;
  left: 0;
`
export const ButtonWrapper = styled.div`
  display: grid;
  justify-items: center;
  margin: 13px;
  .mark-as-read {
    background: #ffffff;
    border: 1px solid #e26827;
    border-radius: 5px;
    color: #e26827;
    padding: 0 10px;
  }
`
export const NoContentContainer = styled.div`
  background: #ffffff;
`
