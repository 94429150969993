import React from "react"
import styled from "styled-components"

interface IFormHeadline {
  title: string
  borderColor?: string
}

interface StyledProps {
  borderColor: string
}

const Wrapper = styled.div<StyledProps>`
  border-left: 5px solid ${(props) => props.borderColor};
  padding-left: 10px;
  p {
    color: #191919;
    font-weight: 500;
    font-size: 18px;
    line-height: 37px;
  }
`

const FormHeadline: React.FC<IFormHeadline> = ({
  title,
  borderColor = "#0782C8",
}) => {
  return (
    <Wrapper borderColor={borderColor}>
      <p>{title}</p>
    </Wrapper>
  )
}

export { FormHeadline }
