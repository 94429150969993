import React, { Dispatch, SetStateAction, useState } from "react"
import { OperationOptionFormWrapper } from "./OperationOptionForm.style"
import { Card } from "antd"
import { useTranslation } from "react-i18next"
import { DatePicker } from "../../../molecules"
import { RadioButtons } from "@project/shared"
import { RightOutlined } from "@ant-design/icons"
import moment from "moment"
import { ChildButton } from "../../../atoms/ChildButton"

interface OperationOptionFormProps {
  queryParams?: any
  setQueryParams: Dispatch<
    SetStateAction<{
      readStatus: string
      start: any
      end: any
    }>
  >
}

export const OperationOptionForm = ({
  queryParams,
  setQueryParams,
}: OperationOptionFormProps) => {
  const { t } = useTranslation()

  const [searchOptions, setSearchOptions] = useState({
    start: queryParams?.start || null,
    end: queryParams?.end || null,
    readStatus: queryParams?.readStatus || "",
  })

  return (
    <OperationOptionFormWrapper>
      <Card title={t("Operation Options")} className={"options__card"}>
        <div className={"options__content"}>
          <div className={"options"}>
            <div className={"options__label"}>
              <RightOutlined />
              {t("Date ")}
            </div>
            <div className={"options__value"}>
              <DatePicker
                onDateChange={(date) =>
                  setSearchOptions({
                    ...searchOptions,
                    start: date,
                  })
                }
                date={searchOptions?.start}
              />
              {"~"}
              <DatePicker
                onDateChange={(date) =>
                  setSearchOptions({
                    ...searchOptions,
                    end: date,
                  })
                }
                date={searchOptions?.end}
              />
            </div>
          </div>
          <div className={"options"}>
            <div className={"options__label"}>
              <RightOutlined />
              {t("Read/Unread")}
            </div>
            <div className={"options__value"}>
              <RadioButtons
                options={[
                  {
                    label: t("All"),
                    value: "",
                  },
                  {
                    label: t("既読"),
                    value: "1",
                  },
                  {
                    label: t("Unread"),
                    value: "0",
                  },
                ]}
                value={searchOptions?.readStatus}
                onChange={(e) =>
                  setSearchOptions({
                    ...searchOptions,
                    readStatus: e?.target?.value,
                  })
                }
              />
            </div>
          </div>
          <div className={" cta__btns"}>
            <ChildButton
              text={t("Display change")}
              typeOf={"primary"}
              onClick={() =>
                setQueryParams({
                  ...searchOptions,
                })
              }
            />
            <ChildButton
              text={t("Cancel filtering")}
              typeOf={"outlined"}
              onClick={() => {
                const initial = {
                  start: moment(),
                  end: moment(),
                  readStatus: "",
                }
                setSearchOptions({
                  ...initial,
                })
                setQueryParams({
                  ...initial,
                })
              }}
            />
          </div>
        </div>
      </Card>
    </OperationOptionFormWrapper>
  )
}
