import React from "react"
import styled from "styled-components"

interface ICalandarCard {
  calandarData: number
  participationData: any
  currentDate: any
}
const Wrapper = styled.div`
  margin: 6px;
  margin-top: 49px;
  padding: 0 !important;
  width: 100%;
  min-height: 60px;
  background: #fce9ce;
  border-radius: 5px;
  text-align: left;
  cursor: default;
`
const Title = styled.div`
  background: #ff8200;
  border-radius: 5px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 37px;
  color: #ffffff;
`
const Text = styled.div`
  min-height: 35px;
  padding: 9px;
  word-break: break-all;
`
const Required = styled.div`
  height: 26px;
  width: 26px;
  color: #ffffff;
  background: #ca4c30;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const RequiredWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  padding-top: 8px;
`
const Listwrapper = styled.div`
  display: content;
  margin-bottom: 10px;
  margin-left: 9px;
  margin-right: 9px;
`
const ItemWrapper = styled.div``

const CalandarCard: React.FC<ICalandarCard> = ({
  participationData,
  currentDate,
}) => {
  return (
    <Wrapper>
      <Title>{"児童発達支援"}</Title>
      <Text>{participationData[currentDate]?.facility_name_short}</Text>
      {["2", "3"].includes(participationData[currentDate]?.attendance_type) ? (
        <RequiredWrapper>
          <Required>{"欠"}</Required>
        </RequiredWrapper>
      ) : participationData[currentDate]?.programs?.length > 0 ? (
        <Listwrapper>
          {participationData[currentDate]?.programs?.map(
            (item, index) =>
              item &&
              item !== "" && (
                <ItemWrapper key={index}>
                  <span style={{ color: "#CA4C30" }}>{"●"}</span>
                  <span>{item}</span>
                </ItemWrapper>
              )
          )}
        </Listwrapper>
      ) : (
        ""
      )}
    </Wrapper>
  )
}

export { CalandarCard }
