import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { useFormik } from "formik"
import { notification, Spin, Typography } from "antd"
import { StyledForm } from "../../../utils"
import styled from "styled-components"
import { Checkbox, Grid, TextField } from "@project/shared"
import { OwnerButton } from "../.."
import { useMutation, useQuery } from "react-query"
import { updateChildEmail, getChildEmail } from "../../../services"
import { t } from "i18next"
import router from "next/router"

const ConfirmEmailInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
`

const StyledTextField = styled(TextField)`
  @media (max-width: 1050px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100% !important;
  }
`

const SelectWrap = styled.div`
  display: flex;
  align-items: center;
`
export const emailOptions = [
  {
    label: t("Enter"),
    value: "1",
  },
  {
    label: t("Exit"),
    value: "0",
  },
]
const ChangeEmailForm: React.FC = () => {
  const { t } = useTranslation()
  const [flagEnable, setFlagEnable] = useState(false)
  const [flagDisable, setFlagDisable] = useState(false)

  const initialValues = {
    email: "",
    new_email: "",
    mail_receive_notification_enter_flag: false,
    mail_receive_notification_leave_flag: false,
  }

  const validationSchema = Yup.object().shape({
    new_email: Yup.string().email(t("Email address format is incorrect")),
    email: Yup.string().oneOf(
      [Yup.ref("new_email")],
      t("Your email does not match.")
    ),
    mail_receive_notification_enter_flag: Yup.bool(),
    mail_receive_notification_leave_flag: Yup.bool(),
  })

  const {
    isLoading,
    isFetching,
    data: childEmail,
    refetch,
  } = useQuery(["fetch-child-email"], getChildEmail, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: 1,
    cacheTime: 0,
  })

  const { isLoading: isUpdating, mutate: mutateChildEmail } = useMutation(
    updateChildEmail,
    {
      onSuccess: () => {
        notification.success({
          message: `${t("Email Reception Setting Updated Successfully")}`,
        })
        formik.resetForm()
        refetch()
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = {
        email: values?.email || childEmail?.data?.Parent?.mail_address,
        mail_receive_notification_enter_flag: flagEnable,
        mail_receive_notification_leave_flag: flagDisable,
      }
      mutateChildEmail(data)
    },
  })

  useEffect(() => {
    if (!isLoading) {
      setFlagEnable(childEmail?.data?.Parent?.mail_receive_noti_enter_flag)
      setFlagDisable(childEmail?.data?.Parent?.mail_receive_noti_leave_flag)
    }
  }, [childEmail])

  if (isFetching || isLoading) {
    return <Spin />
  }

  return (
    <StyledForm onSubmit={formik.handleSubmit} style={{ marginTop: "2rem" }}>
      <Grid className={"grid-header"} background>
        <div className={"label-wrapper"}>
          <div className={"title"}>{t("Current Email")}</div>
        </div>
      </Grid>
      <Grid className={"flex-full grid-row-last"}>
        <StyledTextField
          width={"397px"}
          value={childEmail?.data?.Parent?.mail_address}
          disabled
        />
      </Grid>
      <Grid className={"flex-break"} />

      <Grid className={"grid-header"} background>
        <div className={"label-wrapper"}>
          <div className={"title"}>{t("New Email")}</div>
        </div>
      </Grid>
      <Grid className={"flex-full grid-row-last"}>
        <ConfirmEmailInput>
          <StyledTextField
            width={"397px"}
            name={"new_email"}
            value={formik.values?.new_email}
            onChange={formik.handleChange}
            error={formik?.errors?.new_email}
          />

          <Typography.Text>{t("※Enter if you need to update")}</Typography.Text>
        </ConfirmEmailInput>
      </Grid>
      <Grid className={"flex-break"} />

      <Grid className={"grid-header"} background>
        <div className={"label-wrapper"}>
          <div className={"title"}>{t("Re-enter New Email")}</div>
        </div>
      </Grid>
      <Grid className={"flex-full grid-row-last"}>
        <ConfirmEmailInput>
          <StyledTextField
            width={"397px"}
            name={"email"}
            value={formik.values?.email}
            onChange={formik.handleChange}
            error={formik?.errors?.email}
          />

          <Typography.Text>{t("※Enter if you need to update")}</Typography.Text>
        </ConfirmEmailInput>
      </Grid>
      <Grid className={"flex-break"} />
      <Grid className={"grid-header"} background>
        <div className={"label-wrapper"}>
          <div className={"title"}>{t("Email notification settings")}</div>
        </div>
      </Grid>
      <Grid className={"flex-full grid-row-last"}>
        <SelectWrap>
          <Checkbox
            key={"footerInformation"}
            name={"footerInformation"}
            checked={flagEnable}
            label={t("Enter")}
            onChange={(evt) => {
              setFlagEnable(evt.target.checked)
            }}
          />
          <Checkbox
            key={"footerInformation"}
            name={"footerInformation"}
            checked={flagDisable}
            label={t("Exit")}
            onChange={(evt) => {
              setFlagDisable(evt.target.checked)
            }}
          />
        </SelectWrap>
      </Grid>
      <Grid className={"flex-break"} />
      <div className={"button-wrapper sbn_button-wrapper md_mt-0"}>
        <OwnerButton
          text={t("Cancel")}
          typeOf={"secondary"}
          className={"cancel-btn"}
          type={"button"}
          onClick={() => {
            router.push("/")
          }}
        />
        <OwnerButton
          type={"submit"}
          typeOf={"primary"}
          text={t("Save")}
          isLoading={isUpdating}
        />
      </div>
    </StyledForm>
  )
}

export { ChangeEmailForm }
