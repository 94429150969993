import { t } from "i18next"
import moment from "moment"
import { useRouter } from "next/router"
import styled from "styled-components"

const Wrapper = styled.div`
  height: 40px;
  background-color: #ca4c30;
  border-radius: 12px;
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 21px;
  cursor: pointer;
  @media (max-width: 400px) {
    height: auto;
  }
  .title {
    color: #ffffff;
  }
  .orange-container {
    display: none;
    width: 50px;
    height: 20px;

    background: #e26827;
    border-radius: 12px;
  }
  .facility-notice {
    padding: 0.3rem;
  }
`

const NotificationFacility = () => {
  const router = useRouter()

  const iconPath = "/assets/icons/NotificationAlert.svg"

  moment.locale("en")
  const jpNextMonth = moment().add(1, "month").format("MM月")
  const enNextMonth = moment().add(1, "month").format("MMMM")
  return (
    <Wrapper
      onClick={() => router.push("/facility-application-settings")}
      onTouchEnd={() => router.push("/facility-application-settings")}
    >
      <img src={iconPath} alt={"icon"} />
      <div className={"orange-container"}></div>
      <div className={"title facility-notice"}>
        {t("{{enNextMonth}} Facility application is open", {
          jpNextMonth: jpNextMonth,
          enNextMonth: enNextMonth,
        })}
      </div>
    </Wrapper>
  )
}

export default NotificationFacility
