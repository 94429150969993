import React, { Fragment, useEffect, useState } from "react"
import {
  DetailCommentAndImageContainer,
  DetailLoaderContainer,
  DetailSituationContainer,
  DetailWrapper,
  DetailsContentContainer,
  DetailsTopContainer,
  ProvisionDetailContainer,
  RecorderContainer,
} from "./Details.style"
import { Tag, Button, notification, Skeleton } from "antd"
import { API, Grid, Modal, SignatureCanvas } from "@project/shared"
import { useTranslation } from "react-i18next"
import { CloudDownloadOutlined, FormOutlined } from "@ant-design/icons"
import { CommentsFrom } from "./CommentsFrom/CommentsFrom"
import { useMutation, useQuery } from "react-query"
import {
  fetchAllServiceProvisionCategories,
  getSelfChildDetail,
  getServiceProvisionDetail,
  setMarkAsRead,
  setMarkAsUnRead,
} from "../../../../services"
import { useRouter } from "next/router"
import moment from "moment"
import Head from "next/head"
import { toastMsg } from "../../../../utils/toast-msg"

export const Details = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { id } = router?.query as any
  //fetch details
  const {
    data: serviceProvisionDetail,
    isLoading,
    refetch,
  } = useQuery<any, Error>({
    queryKey: ["service_provision_detail", id],
    queryFn: () => getServiceProvisionDetail(id),
    select: (response) => {
      return {
        ...response?.data,
        images: [
          response?.data?.image_1,
          response?.data?.image_2,
          response?.data?.image_3,
          response?.data?.image_4,
        ],
        service_content_checked_items:
          response?.data?.service_content_checked_items
            ?.split(",")
            ?.map((v) => +v),
      }
    },
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
  })

  // Fetch childData for Settings to show/hide options
  const { data: childData, isLoading: isChildLoading } = useQuery<any, Error>(
    ["fetch-self-child"],
    getSelfChildDetail,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) => ({
        myPageSettings: data?.my_page_setting,
      }),
    }
  )
  useEffect(() => {
    if (!isLoading)
      setSignatures({ digital_sign: serviceProvisionDetail.digital_sign })
  }, [isLoading])

  //fetch service provision detail(categories)
  const { data: serviceProvisionCategories } = useQuery<any, Error>({
    queryKey: ["service_provision_detail_categories"],
    queryFn: () => fetchAllServiceProvisionCategories(),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
  })

  const [showButton, setShowButton] = useState(false)
  const [openSignatureModal, setOpenSignatureModal] = useState(false)
  const [signOf, setSignOf] = useState("")
  const [sign, setSign] = useState("")
  const [signType, setSignType] = useState<"date" | "name">("name")
  const [signatures, setSignatures] = useState({
    digital_sign: null,
  })

  const { mutate: markAsRead, isLoading: isMarkAsRead } = useMutation(
    setMarkAsRead,
    {
      onSuccess: () => {
        refetch()
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const { mutate: markAsUnRead, isLoading: isMarkAsUnRead } = useMutation(
    setMarkAsUnRead,
    {
      onSuccess: () => {
        refetch()
        setShowButton(true)
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const callAnApi = async ({ values }) => {
    return API.post(`/child-parent/update-signature/${id}`, values)
  }

  const { mutate } = useMutation({
    mutationFn: callAnApi,
  })

  const saveSignatures = (key: string, val: string) => {
    mutate(
      {
        values: {
          ...signatures,
          [key]: val,
        },
      },
      {
        onSuccess: () => {
          setOpenSignatureModal(false)
          setSignatures({
            ...signatures,
            [key]: val,
          })
          toastMsg("success", t("Updated Successfully"))
        },
        onError: () => {
          toastMsg(
            "error",
            t("Something went wrong. Please contact administrator.")
          )
        },
      }
    )
  }

  const title = t("Service provision record for {{date}}", {
    date: moment(serviceProvisionDetail?.date).format("YYYY/MM/DD"),
  })

  // Default mark as read only on page load
  useEffect(() => {
    markAsRead(id)
  }, [])

  const temp =
    !isLoading &&
    serviceProvisionDetail?.child_service_content_results?.temperature &&
    Number(serviceProvisionDetail?.child_service_content_results?.temperature) >
      0 &&
    t("Body temperature") +
      " " +
      serviceProvisionDetail?.child_service_content_results?.temperature
        .toFixed(2, 1)
        .slice(0, -1) +
      "°C"
  const provideImageWithURL = serviceProvisionDetail?.images?.filter((v) => v)
  const [isValidUrl, setIsValidUrl] = useState(true)

  const renderSignature = (url) => (
    <>
      {url && isValidUrl ? (
        <img
          src={url}
          alt={"signature"}
          className={"sign-img"}
          onError={() => setIsValidUrl(false)}
        />
      ) : (
        ""
      )}
    </>
  )

  return (
    <>
      <Head>
        <title></title>
      </Head>
      <DetailWrapper>
        <DetailsTopContainer>
          {isLoading || isChildLoading ? (
            <>
              <Skeleton.Button
                active
                shape={"round"}
                style={{
                  marginRight: "0.3em",
                }}
              />
              <Skeleton.Input active />
            </>
          ) : (
            <>
              <Tag className={"top__title-tag"}>{t("Child")}</Tag>
              <h3 className={"top__title-text"}>{title}</h3>
            </>
          )}
        </DetailsTopContainer>
        <DetailsContentContainer>
          {isLoading ? (
            <DetailLoaderContainer>
              <br />
              <div className={"info"}>
                <div className={"loader__content"}>
                  <Skeleton.Input active size={"large"} />
                  <Skeleton.Input
                    block
                    active
                    size={"large"}
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
                <div className={"loader__content"}>
                  <Skeleton.Input active size={"large"} />
                  <Skeleton.Input
                    block
                    active
                    size={"large"}
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
                <div className={"loader__content"}>
                  <Skeleton.Input active size={"large"} />
                  <Skeleton.Input
                    block
                    active
                    size={"large"}
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
              </div>
              <br />
              <div className={"situation__loader"}>
                <div className={"situation__loader__top"}>
                  {t("Today's situation")}
                </div>
                <Skeleton.Input block active size={"large"} />
              </div>
              <br />
              <div className={"provision__loader"}>
                <div className={"provision__loader__top"}>
                  {t("Service provision details")}
                </div>
                <div className={"loader__content"}>
                  <Skeleton.Input active size={"large"} />
                  <Skeleton.Input active size={"large"} block />
                </div>
                <div className={"loader__content"}>
                  <Skeleton.Input active size={"large"} />
                  <Skeleton.Input active size={"large"} block />
                </div>
              </div>
              <br />
              <div className={"loader__recorder"}>
                <Skeleton.Input active size={"large"} />
                <Skeleton.Input active size={"large"} block />
              </div>
              <br />
              <Skeleton.Input active size={"large"} />
            </DetailLoaderContainer>
          ) : (
            <>
              <DetailCommentAndImageContainer
                column={
                  provideImageWithURL?.length >= 3
                    ? "1.5fr 1fr"
                    : provideImageWithURL?.length === 2
                    ? "1.8fr 1fr"
                    : provideImageWithURL?.length === 1
                    ? "1fr 0.2fr"
                    : "1fr 0fr"
                }
                gap={provideImageWithURL?.length}
              >
                <div className={"comment__situation__container"}>
                  <DetailSituationContainer>
                    <div className={"situation__top"}>
                      {t("Today's situation")}
                    </div>
                    <div className={"situation__comments"}>
                      {serviceProvisionDetail?.comment}
                    </div>
                  </DetailSituationContainer>
                  <div className={"comment__content"}>
                    {!isLoading && (
                      <CommentsFrom
                        date={
                          moment(serviceProvisionDetail?.date).isValid()
                            ? moment(serviceProvisionDetail?.date).format(
                                "YYYY-MM-DD"
                              )
                            : ""
                        }
                        isLoading={isLoading}
                        parentComment={serviceProvisionDetail?.parent_comment}
                      />
                    )}
                  </div>
                </div>
                {provideImageWithURL?.length > 0 && (
                  <div className={"details__images"}>
                    {serviceProvisionDetail?.images
                      ?.filter((v) => v)
                      ?.map((src, index) => (
                        <div key={index} className={"image__container"}>
                          <div
                            className={"download_icon_container"}
                            onClick={async () => {
                              const imageURL = src?.replace(
                                "/thumbnail/",
                                "/original/"
                              )
                              const imageData = await fetch(imageURL)
                                .then((response) => response.arrayBuffer())
                                .then(
                                  (buffer) =>
                                    new Blob([buffer], { type: "image/png" })
                                )
                              const link = document?.createElement("a")
                              link.href = URL.createObjectURL(imageData)
                              link.download = `${serviceProvisionDetail?.child?.child_name}-${index}`
                              document.body.appendChild(link)
                              link.click()
                              document.body.removeChild(link)
                            }}
                          >
                            <CloudDownloadOutlined />
                          </div>
                          <div
                            className={"image"}
                            style={{
                              backgroundImage: `url(${src})`,
                            }}
                          />
                        </div>
                      ))}
                  </div>
                )}
              </DetailCommentAndImageContainer>
              <div className={"basic__info"}>
                <Grid className={"grid-header"} background width={"280px"}>
                  {t("Children using the service")}
                </Grid>
                <Grid className={"flex-full grid-row-last"}>
                  {serviceProvisionDetail?.child?.child_name || ""}
                </Grid>
                <Grid className={"flex-break"} />

                <Grid className={"grid-header"} background width={"280px"}>
                  {t("Facilities used")}
                </Grid>
                <Grid className={"flex-full grid-row-last"}>
                  {serviceProvisionDetail?.facility?.facility_name_short || ""}{" "}
                  {
                    serviceProvisionDetail?.child_service_content_results
                      ?.start_time
                  }{" "}
                  {"-"}{" "}
                  {
                    serviceProvisionDetail?.child_service_content_results
                      ?.end_time
                  }{" "}
                  {String(temp) !== "0" && temp}
                  <br />
                </Grid>
                <Grid className={"flex-break"} />
              </div>
              <div>
                <ProvisionDetailContainer>
                  <div className={"provision__top"}>
                    {t("Service provision details")}
                  </div>

                  <div className={"provision__content"}>
                    {serviceProvisionCategories?.data?.map((pCategory) => (
                      <Fragment key={pCategory?.id}>
                        <Grid className={"grid-header"} background>
                          {pCategory?.service_content_category_name || ""}
                        </Grid>

                        <Grid className={"flex-full grid-row-last"}>
                          <div className={"items"}>
                            {pCategory?.service_provision_items
                              ?.filter((item) =>
                                serviceProvisionDetail?.service_content_checked_items?.includes(
                                  item?.id
                                )
                              )
                              ?.map((v) => (
                                <p key={v?.id}>{v?.name || ""}</p>
                              ))}
                          </div>
                        </Grid>
                        <Grid className={"flex-break"} />
                      </Fragment>
                    ))}
                  </div>
                </ProvisionDetailContainer>
                {+childData?.myPageSettings
                  ?.service_provision_care_record_digital_sign === 1 ? (
                  <div className={"basic__info"}>
                    <Grid className={"grid-header"} background width={"300px"}>
                      {t("Digital Signature")}
                    </Grid>
                    <Grid
                      className={"flex-full grid-row-last signature_content"}
                    >
                      {renderSignature(signatures?.digital_sign)}
                      <FormOutlined
                        onClick={() => {
                          setSign(signatures?.digital_sign)
                          setSignType("name")
                          setOpenSignatureModal(true)
                          setSignOf("digital_sign")
                        }}
                      />
                    </Grid>
                    <Grid className={"flex-break"} />
                  </div>
                ) : (
                  <></>
                )}
                <RecorderContainer>
                  <Grid className={"grid-header"} background width={"256px"}>
                    <b>{t("Recorder")}</b>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    {serviceProvisionDetail?.staff?.staff_name}
                  </Grid>
                  <Grid className={"flex-break"} />
                  <br />
                  {(showButton ||
                    serviceProvisionDetail?.already_read_flag) && (
                    <Button
                      size={"large"}
                      className={`status__btn ${
                        serviceProvisionDetail?.already_read_flag
                          ? "status__btn-green"
                          : "status__btn-red"
                      }`}
                      onClick={() => {
                        if (!serviceProvisionDetail?.already_read_flag)
                          markAsRead(id)
                        else markAsUnRead(id)
                      }}
                      loading={isMarkAsRead || isMarkAsUnRead}
                      //  disabled={isMarkAsRead || isMarkAsUnRead}
                    >
                      {serviceProvisionDetail?.already_read_flag
                        ? t("Already read")
                        : t("Mark as read")}
                    </Button>
                  )}
                </RecorderContainer>
              </div>
            </>
          )}
        </DetailsContentContainer>
      </DetailWrapper>
      {openSignatureModal && (
        <Modal
          open={openSignatureModal}
          title={t("Confirmation signature")}
          onCancel={() => {
            setOpenSignatureModal(false)
          }}
          footer={null}
          bodyStyle={{
            padding: 0,
          }}
          width={450}
        >
          <SignatureCanvas
            sign={sign}
            signType={signType}
            onSave={(val) => {
              saveSignatures(signOf, val)
            }}
          />
        </Modal>
      )}
    </>
  )
}
