import { theme } from "@project/shared"
import styled from "styled-components"

export const StyledNotice = styled.div`
  margin: 34px 0;
  background-color: #ca4c30;
  border-radius: 12px;
  width: 100%;
  padding: 11px 21px;
  display: flex;
  align-items: center;
  cursor: pointer;
  * {
    font-size: 14px;
  }
  .tag {
    margin-left: 9px;
    margin-right: 4px;
    font-size: 14px;
    color: white;
    border-radius: 12px;
    background-color: #e26827;
    border: none;
    width: auto;
    max-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .head {
    display: flex;
    align-content: center;
    border-right: 1px solid #8e8e8e;
    margin-right: 8px;
  }
  .contents {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    color: white;
    font-weight: 400;
    .body {
      display: flex;
      flex-wrap: wrap;
    }
    .heading {
      margin-right: 20px;
    }
    .text {
      justify-self: left;
    }

    @media (max-width: ${theme.breakpoints?.sm}) {
      * {
        font-size: 12px;
      }
    }
  }
`
