import { theme } from "@project/shared"
import { Typography } from "antd"
import { useRouter } from "next/router"
import React from "react"
import styled from "styled-components"

export type GridderShape = {
  title: string
  iconPath?: string
  isActive?: boolean
  routes?: string
  requiresPermission?: boolean
  permissionKeys?: Array<string>
}
interface GridderProps {
  title: string
  items: GridderShape[]
  activeColor?: string
}

interface StyledColProps {
  isActive: boolean
  activeColor?: string
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .ant-typography {
    font-size: 20px;
    font-weight: 500;
    color: #191919;
    margin-bottom: 20px;
  }
  .ant-card.ant-card-bordered:hover {
    transition: 0.5s ease-out;
    border: 1px solid #fdd95f;

    .ant-avatar.ant-avatar-circle.ant-avatar-image {
      transition: 0.5s ease-out;
      background-color: rgba(253, 217, 95, 0.3);
    }
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(132px, 1fr));
  column-gap: 20px;
  row-gap: 20px;
`

const ImageContainer = styled.div<StyledColProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d9dee0;
  border-radius: 12px;
  padding: 8px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isActive, activeColor }) =>
    isActive ? (activeColor ? theme.bgOrange : "#fff4d1") : "#D9DEE0"};
  .title {
    text-align: center;
    margin-top: 12px;
  }
  .image_container {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: ${({ isActive, activeColor }) =>
      isActive ? activeColor || "#fff4d1" : "#f3f3f3"};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const { Title } = Typography
const Gridder: React.FC<GridderProps> = ({ title, items, activeColor }) => {
  const router = useRouter()
  return (
    <Wrapper>
      <Title level={2}>{title}</Title>
      <Container>
        {items.map((item, index) => (
          <ImageContainer
            key={index}
            isActive={item.isActive}
            activeColor={activeColor}
            onClick={() => router.push(item.routes)}
          >
            <div className={"image_container"}>
              <img src={item?.iconPath} title={item?.title} />
            </div>
            <span className={"title"}>{item?.title}</span>
          </ImageContainer>
        ))}
      </Container>
    </Wrapper>
  )
}

export { Gridder }

//  cursor: pointer;
//     border-width: 1px;
//     border-style: solid;
//     border-color: ${({ isActive, activeColor }) =>
//       isActive ? (activeColor ? theme.bgOrange : "#fff4d1") : "#f3f3f3"};
//     border-radius: 12px;
//     min-height: 100%;
