import React from "react"
import styled from "styled-components"
import CopyIcon from "../../../public/assets/icons/CopyIcon"
import PrintIcon from "../../../public/assets/icons/PrintIcon"
import LeftArrowIcon from "../../../public/assets/icons/LeftArrowIcon"
import SearchIcon from "../../../public/assets/icons/SearchIcon"
import PdfIcon from "../../../public/assets/icons/pdf.svg"
import {
  CloseCircleFilled,
  FormOutlined,
  LoadingOutlined,
  PlusCircleFilled,
} from "@ant-design/icons"
import { theme } from "@project/shared"
import { Spin } from "antd"
import ArrowDownIcon from "../../../public/assets/icons/ArrowDownIcon"
export interface Props {
  text?: string
  icon?:
    | "search"
    | "print"
    | "copy"
    | "left-arrow"
    | "none"
    | "add"
    | "close"
    | "check-all"
    | "edit"
    | "down"
    | "printBlack"
    | "pdf"
  iconPosition?: "left" | "right"
  shape?: "round" | "circle"
  color?: string
  borderColor?: string
  backgroundColor?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  type?: "submit" | "button"
  padding?: number
  radius?: number
  className?: string
  width?: number
  disabled?: boolean
  typeOf?:
    | "primary"
    | "secondary"
    | "search"
    | "copy"
    | "draft"
    | "check-all"
    | "outlined"
    | "link"
    | "print"
    | "show"
    | "printBlack"
    | "pdf"
  isLoading?: boolean
}

interface StyledButtonProps {
  color: string
  backgroundColor: string
  borderColor: string
  padding?: number
  radius?: number
  width?: number
  typeOf?: "primary" | "secondary"
  isLoading?: boolean
}

interface IconPosition {
  position: string
}

const ButtonStyled = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  cursor: pointer;
  padding: ${(props) => (props?.padding ? `${props?.padding}px` : `12px 30px`)};
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  &.circular-radius {
    border-radius: 100px;
  }
  &.round-radius {
    border-radius: ${(props) => (props?.radius ? `${props?.radius}px` : "5px")};
  }
  font-size: 14px;
  width: ${(props) => (props?.width ? `${props?.width}px` : ``)};
`

const IconStyled = styled.div<IconPosition>`
  display: flex;
  align-items: center;
  margin-right: ${(props) => (props.position === "left" ? "10px" : "0px")};
  margin-left: ${(props) => (props.position === "right" ? "10px" : "0px")};
`

const Button: React.FC<Props> = (props) => {
  const {
    text,
    icon = "none",
    iconPosition = "left",
    shape = "round",
    color = "#000000",
    borderColor = "transparent",
    backgroundColor = "#F6F7F8",
    onClick,
    className,
    type = "button",
    disabled = false,
    isLoading,
  } = props
  const getIcon = (position) => {
    switch (icon) {
      case "search":
        return (
          <IconStyled position={position}>
            <SearchIcon />
          </IconStyled>
        )
      case "print":
        return (
          <IconStyled position={position}>
            <PrintIcon />
          </IconStyled>
        )
      case "pdf":
        return (
          <IconStyled position={position}>
            <PdfIcon />
          </IconStyled>
        )
      case "copy":
        return (
          <IconStyled position={position}>
            <CopyIcon />
          </IconStyled>
        )
      case "left-arrow":
        return (
          <IconStyled position={position}>
            <LeftArrowIcon />
          </IconStyled>
        )
      case "down":
        return (
          <IconStyled position={position}>
            <ArrowDownIcon />
          </IconStyled>
        )

      case "add":
        return (
          <IconStyled position={position}>
            <PlusCircleFilled style={{ color: theme.gray3 }} />
          </IconStyled>
        )
      case "close":
        return (
          <IconStyled position={position}>
            <CloseCircleFilled style={{ color: theme.alert }} />
          </IconStyled>
        )
      case "check-all":
        return (
          <IconStyled position={position}>
            <img src={"/assets/icons/CheckAllIcon.svg"} />
          </IconStyled>
        )
      case "edit":
        return (
          <IconStyled position={position}>
            <FormOutlined />
          </IconStyled>
        )
    }
    return <></>
  }
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />
  if (isLoading) {
    return (
      <ButtonStyled
        className={
          shape === "round"
            ? `round-radius ${className}`
            : `circular-radius ${className}`
        }
        color={color}
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        onClick={onClick}
        type={type}
        padding={props?.padding}
        radius={props?.radius}
        width={props?.width}
        disabled
      >
        <IconStyled position={"left"} style={{ height: "19px" }}>
          <Spin indicator={antIcon} style={{ color: `${color || "white"}` }} />
        </IconStyled>
        {text}
      </ButtonStyled>
    )
  }
  return (
    <ButtonStyled
      className={
        shape === "round"
          ? `round-radius ${className}`
          : `circular-radius ${className}`
      }
      color={color}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      onClick={onClick}
      type={type}
      padding={props?.padding}
      radius={props?.radius}
      width={props?.width}
      disabled={disabled}
    >
      {icon && iconPosition === "left" && getIcon(iconPosition)}
      {text}
      {icon && iconPosition === "right" && getIcon(iconPosition)}
    </ButtonStyled>
  )
}

export { Button }
