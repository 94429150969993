import { theme } from "@project/shared"
import { t } from "i18next"
import { useRouter } from "next/router"
import React, { useContext } from "react"
import styled from "styled-components"
import { AuthContext } from "../../../utils"
import { Gridder } from "../../molecules"

interface INavComponent {
  title: string
}

const Title = styled.div`
  margin-top: 30px;
  font-weight: 500;
  font-size: 20px;
  @media print {
    display: none;
  }
`
const Container = styled.div`
  .ant-card-body {
    padding: 20px 3px;
  }
  .ant-card-body .ant-card-meta {
    min-width: 237px;
  }
  @media (max-width: 1430px) {
    .ant-card-body .ant-card-meta {
      width: 100%;
    }
  }
  @media (max-width: 820px) {
    .ant-card-body .ant-card-meta .ant-card-meta-title {
      margin-top: 0;
    }
  }
  @media print {
    display: none;
  }
`

const NavComponent: React.FC<INavComponent> = ({ title }) => {
  const router = useRouter()
  const { childInformation } = useContext(AuthContext)
  const items: any[] = [
    {
      title: t("Service details provided"), //Service provision record
      iconPath: "/assets/icons/service-delivery-sheet.svg",
      routes: "/service-provision-record",
      isActive:
        router.pathname === "/service-provision-record" ||
        router.pathname.startsWith("/service-provision-record/"),
      key: "service_provision_record",
    },

    {
      title: t("Contact facility"),
      iconPath: "/assets/icons/communication.svg",
      routes: "/contact-facility",
      isActive: router.pathname.startsWith("/contact-facility"),
      key: "contact_facility",
    },
    {
      title: t("Today's program"), //Program record
      iconPath: "/assets/icons/calender-with-tick.svg",
      routes: "/program-record",
      isActive: router.pathname.startsWith("/program-record"),
      key: "activity_record",
    },

    {
      title: t("Service provision record sheet"),
      iconPath: "/assets/icons/service-provision.svg",
      routes: "/service-provision-record-sheet",
      isActive: router.pathname.startsWith("/service-provision-record-sheet"),
      key: "service_provision_record_sheet",
    },
    {
      title: t("Email reception settings"),
      iconPath: "/assets/icons/openMail.svg",
      routes: "/change-email",
      isActive: router.pathname.startsWith("/change-email"),
      key: "mail_setting",
    },
    {
      title: t("Individual support plan"),
      iconPath: "/assets/icons/coloredMonitoring.svg",
      routes: "/individual-support-plans",
      isActive: router.pathname.startsWith("/individual-support-plans"),
      key: "individual_support_plan_list",
    },
    {
      title: t("Individual support plan (draft)"),
      iconPath: "/assets/icons/coloredMonitoring.svg",
      routes: "/individual-support-draft-plans",
      isActive: router.pathname.startsWith("/individual-support-draft-plans"),
      key: "individual_support_plan_draft_list",
    },
    {
      title: t("Monitoring"),
      iconPath: "/assets/icons/coloredMonitoring.svg",
      routes: "/monitoring",
      isActive: router.pathname.startsWith("/monitoring"),
      key: "monitoring_list",
    },
  ]
  const filterCardItems = () => {
    const enabledCardItems = Array(5)?.fill("")
    if (childInformation?.my_page_setting?.activity_record) {
      enabledCardItems.splice(0, 1, "activity_record")
    }
    if (childInformation?.my_page_setting?.service_provision_record) {
      enabledCardItems.splice(1, 1, "service_provision_record")
    }
    if (childInformation?.my_page_setting?.activity_record) {
      enabledCardItems.splice(2, 1, "activity_record")
    }
    if (childInformation?.my_page_setting?.service_provision_record_sheet) {
      enabledCardItems.splice(3, 1, "service_provision_record_sheet")
    }
    if (childInformation?.my_page_setting?.contact_facility) {
      enabledCardItems.splice(4, 1, "contact_facility")
    }
    if (childInformation?.my_page_setting?.mail_setting) {
      enabledCardItems.splice(5, 1, "mail_setting")
    }
    if (childInformation?.my_page_setting?.individual_support_plan_list) {
      enabledCardItems.splice(6, 1, "individual_support_plan_list")
    }
    if (childInformation?.my_page_setting?.individual_support_plan_draft_list) {
      enabledCardItems.splice(7, 1, "individual_support_plan_draft_list")
    }
    if (childInformation?.my_page_setting?.monitoring_list) {
      enabledCardItems.splice(8, 1, "monitoring_list")
    }

    return items?.filter((item) => enabledCardItems?.includes(item?.key))
  }

  const handleVisibilityOfNav = (route: string) => {
    const regex = "/service-provision-record/*"
    if (
      route === `/program-record` &&
      !childInformation?.my_page_setting?.activity_record
    ) {
      return <div />
    }
    if (
      route?.match(regex) &&
      route !== "/service-provision-record-sheet" &&
      !childInformation?.my_page_setting?.service_provision_record
    ) {
      router.push("/")
    }
    if (
      route === "/service-provision-record-sheet" &&
      !childInformation?.my_page_setting?.service_provision_record_sheet
    ) {
      return <div />
    }
    if (
      route === "/contact-facility" &&
      !childInformation?.my_page_setting?.contact_facility
    ) {
      return <div />
    }

    return (
      <Gridder
        items={filterCardItems()}
        title={""}
        activeColor={theme.orangeLight}
      />
    )
  }

  return (
    <>
      <Title>{title}</Title>
      <Container>{handleVisibilityOfNav(router?.pathname)}</Container>
    </>
  )
}

export { NavComponent }
