import * as React from "react"
import { DatePickerProps } from "antd"
import CalendarIcon from "../../../public/assets/icons/CalendarIcon"
import { SharedDatePicker } from "@project/shared"

interface Props {
  date?: null | moment.Moment
  placeholder?: string
  onDateChange: (value: moment.Moment | null) => void
  className?: string
  style?: React.CSSProperties
  name?: string
  disabled?: boolean
  error?: any
  showArrow?: boolean
  clear?: boolean
}

const DatePicker: React.FC<Props & DatePickerProps> = (props) => (
  <SharedDatePicker {...props} icon={CalendarIcon} />
)
export { DatePicker }
