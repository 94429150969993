import { theme } from "@project/shared"
import styled from "styled-components"
export const CommentWrapper = styled.div`
  .text__title {
    padding-bottom: 0.5rem;
    font-weight: 500;
  }

  .parent__comment {
    border: 1px solid ${theme.gray2};
    border-radius: 4px;
    padding: 1rem;
    width: 100%;
    min-height: 190px;
    background-color: ${theme.bgColor};
  }
  .cta__btn {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: fit-content;
    margin-top: 1rem;
  }
`
