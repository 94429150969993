import React from "react"
import styled from "styled-components"
import { theme } from "@project/shared"

const Header = styled.header`
  width: 100%;
  background: ${theme.bgOrange};
  display: flex;
  padding: 10px 0px 10px 40px;
  .image-wrapper {
    background: white;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 30px;
    }
  }
  p {
    margin: auto 0px;
    margin-left: 20px;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
  }
`

const UnAuthorizedHeader = () => {
  return (
    <Header>
      <div className={"image-wrapper"}>
        <img src={"/assets/icons/logo.png"} />
      </div>
      <p></p>
    </Header>
  )
}
export { UnAuthorizedHeader }
