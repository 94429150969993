import { auth, theme } from "@project/shared"
import { Layout } from "antd"
import { signOut } from "firebase/auth"
import { t } from "i18next"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { AuthContext } from "../../../utils"

const { Header: AntHeader } = Layout

export interface Props {
  navColor?: string
}

const HeaderStyled = styled(AntHeader)`
  background-color: #ffffff;
  padding: 0;
  max-height: 16rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  @media (max-width: 786px) {
    background-color: ${theme.bgOrange};
  }
  @media print {
    display: none;
  }
  &.ant-layout-header {
    height: auto;
  }
`
const LeftSide = styled.div`
  display: flex;
  flex: 1.4;
  @media screen and (max-width: ${theme.breakpoints.lg}) {
    flex: auto;
  }
`

const Icon = styled.div<IconProps>`
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props?.backgroundColor || theme.bgOrange};
  border-radius: 0px 0px 12px 0px;
  @media (max-width: 786px) {
    height: 100%;
  }
  .image-wrapper {
    background: white;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    min-height: 60px;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    margin: 20px;

    .default-image {
      height: 50%;
      width: auto;
      border-radius: 0%;
    }
  }
  p {
    margin: auto 0px;

    font-weight: 500;
    font-size: 16px;
    color: white;
    height: 60px;
    padding-right: 28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 497px) {
      max-width: 212px;
    }
  }
  .title-wrapper {
    color: #fff;
    line-height: 20px;
    padding-right: 20px;
  }
`
export interface SettingsProps {
  backgroundColor?: string
}
const Settings = styled.div<SettingsProps>`
  background: ${(props) => props?.backgroundColor || theme.bgOrange};
  min-width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  cursor: pointer;
  .setting-img {
    margin-top: 10px;
  }
`

const Text = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 37px;
  text-align: center;
  color: #ffffff;
  white-space: pre-line;
`

const Menu = styled.div<SettingsProps>`
  position: absolute;
  background: #ffffff;
  right: 0;
  top: 100px;
  z-index: 100;
  width: 289px;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  border: 1px ${theme.bgOrange} solid;
  .active {
    background-color: #f1f4f5;
  }
  .top-item {
    background: ${(props) => props?.backgroundColor || theme.bgOrange};
    color: white;
    cursor: auto;
    text-decoration: none;
    border-top-left-radius: 12px;
    .setting-img {
      padding-right: 10px;
    }
  }
  @media (max-width: 786px) {
    width: 100%;
  }
`
const MenuItem = styled.div`
  cursor: pointer;
  text-decoration: underline;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  .lock-image {
    padding-right: 10px;
  }
`
const RightSide = styled.div`
  display: flex;
  gap: 0.8rem;
  flex: 1;
  justify-content: flex-end;
`
const HeaderOtherInfo = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    text-align: right;
    color: ${theme?.black};
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20.27px;
  }
  div.name {
    font-weight: bold;
  }
  @media screen and (max-width: ${theme.breakpoints.lg}) {
    display: none;
  }
`
export interface IconProps {
  backgroundColor?: string
}
const Header: React.FC<Props> = (additionalProps) => {
  const router = useRouter()
  const pathname = router.pathname
  const { navColor } = additionalProps
  const { childInformation, companyInfo, facilities } = useContext(AuthContext)
  const menuRef: any = useRef()
  const settingRef: any = useRef()
  const [showMenu, setShowMenu] = useState(false)
  const logOut = () => {
    signOut(auth)
    window.location.href = "/login"
  }

  const handleSetting = () => {
    setShowMenu((prev) => !prev)
  }
  useEffect(() => {
    /**
     * Hide menu if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !settingRef.current.contains(event.target)
      ) {
        setShowMenu(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [menuRef])

  return (
    <>
      <HeaderStyled>
        <LeftSide>
          <Icon backgroundColor={navColor}>
            <div className={"image-wrapper"}>
              {childInformation?.my_page_setting?.logo_image ? (
                <img
                  src={childInformation?.my_page_setting?.logo_image}
                  alt={t("logo")}
                />
              ) : (
                <img
                  className={"default-image"}
                  src={"/assets/icons/logo.svg"}
                  alt={t("logo")}
                />
              )}
            </div>
            <div className={"title-wrapper"}>
              {`${childInformation?.child_name || ""} さん `} <br />
              {t("dedicated page")}
            </div>
          </Icon>
        </LeftSide>

        <RightSide>
          {childInformation?.my_page_setting?.header_type === 1 && (
            <HeaderOtherInfo>
              <Text>{childInformation?.my_page_setting?.header_text}</Text>
            </HeaderOtherInfo>
          )}
          {childInformation?.my_page_setting?.header_type === 2 && companyInfo && (
            <HeaderOtherInfo>
              <Text className={"name"}>{companyInfo?.company_name}</Text>
              <Text>{`〒 ${companyInfo?.zip_code} ${companyInfo?.address}`}</Text>
              <Text>{`TEL.${companyInfo?.tel_no} / FAX.${companyInfo?.fax_no}`}</Text>
            </HeaderOtherInfo>
          )}
          {childInformation?.my_page_setting?.header_type === 3 && (
            <HeaderOtherInfo>
              {facilities
                ?.filter(
                  (v) =>
                    +childInformation?.my_page_setting?.header_facility_id ===
                    +v?.id
                )
                ?.map((facility) => (
                  <div key={facility?.id} className={"footer__facility"}>
                    <Text className={"name"}>{facility?.facility_name}</Text>
                    <Text className={"address"}>{facility?.address}</Text>
                    {facility?.phone_no && (
                      <div className={"phone"}>
                        <span>{t("TEL")}</span> {":"} {facility?.phone_no}
                      </div>
                    )}
                    {facility?.fax_no && (
                      <div className={"fax"}>
                        <span>{t("Fax")}</span> {":"} {facility?.fax_no}
                      </div>
                    )}
                  </div>
                ))}
            </HeaderOtherInfo>
          )}

          <Settings
            backgroundColor={navColor}
            onClick={handleSetting}
            ref={settingRef}
          >
            <img
              className={"setting-img"}
              src={"/assets/icons/setting-ico.svg"}
            />
            <Text>{t("Settings")}</Text>
          </Settings>
        </RightSide>
        {!!showMenu && (
          <Menu ref={menuRef}>
            <MenuItem className={"top-item"}>
              <img
                className={"setting-img"}
                src={"/assets/icons/setting-ico.svg"}
              />
              {t("User settings menu")}
            </MenuItem>
            {childInformation?.my_page_setting?.password_change ? (
              <MenuItem
                className={pathname === "/change-password" ? "active" : ""}
                onClick={() => {
                  setShowMenu(false)
                  router.push("/change-password")
                }}
              >
                {t("Password update")}
              </MenuItem>
            ) : (
              ""
            )}
            {childInformation?.my_page_setting?.mail_setting ? (
              <MenuItem
                className={pathname === "/change-email" ? "active" : ""}
                onClick={() => {
                  setShowMenu(false)
                  router.push("/change-email")
                }}
              >
                {t("Email reception settings")}
              </MenuItem>
            ) : (
              ""
            )}

            <MenuItem
              onClick={() => {
                setShowMenu(false)
                logOut()
              }}
            >
              <img
                className={"lock-image"}
                src={"/assets/icons/lock-icon.svg"}
              />
              {t("Logout")}
            </MenuItem>
          </Menu>
        )}
      </HeaderStyled>
    </>
  )
}

export { Header }
