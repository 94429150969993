import { theme } from "@project/shared"
import styled from "styled-components"

export const OperationOptionFormWrapper = styled.div`
  margin-top: 1rem;
  .options__card {
    border-radius: 12px !important;
  }

  .options__content {
    width: 100%;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    .options {
      display: grid;
      grid-template-columns: 130px minmax(300px, 1fr);
      grid-gap: 0.5rem;
      &__label {
        svg {
          color: ${theme.gray2};
        }
      }
      &__value {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        @media screen and (max-width: ${theme.breakpoints.sm}) {
          flex-wrap: wrap;
        }
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      }
    }
    .cta__btns {
      display: flex;
      gap: 1rem;
      button {
        width: fit-content;
        display: flex;
        align-items: center;
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        flex-wrap: wrap;
      }
    }
  }

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    .options__card {
      .ant-card-head,
      .ant-card-body {
        padding: 12px;
      }
    }
  }
`
