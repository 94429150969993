import React from "react"
import { t } from "i18next"
import moment from "moment"
import { Button } from "antd"
import Calendar from "react-calendar"
import styled from "styled-components"
import { theme } from "@project/shared"
import { CalandarCard } from "../../molecules"
import { getMonth } from "@wojtekmaj/date-utils"
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { DataContainer, Divisor, FormTitle } from "../../../utils/shared-style"

interface iCalanderListComponent {
  year?: any
  month?: any
  handleDisplayChange: ({ year, month }: { year: any; month: any }) => void
  participationData?: any
  serviceType?: any
}

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`
const Tag = styled.div`
  width: 70px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #f1f4f5;
  font-weight: 400;
  font-size: 14px;
  @media (max-width: 877px) {
    margin-bottom: 10px;
  }
`
const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
`
const Value = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${theme.bgOrange};
`

export const StyledMonthPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    background: white;
    color: black;
    border: 1px solid #d2d1d1;
    border-radius: 10px;
    height: 40px;
    margin-right: 10px;
    padding: 0px;
    min-width: 75px;
    &.active {
      background: rgba(7, 130, 200, 0.2);
    }
  }
`
const StatsWrapper = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-right: 20px;
    margin-bottom: 10px;
  }
  @media (max-width: 877px) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`

const StyledCalendar = styled(Calendar)`
  margin-top: 25px;
  margin-bottom: 25px;
  padding-left: 30px;
  [class*="neighboringMonth"] {
    cursor: default;
    abbr {
      cursor: default;
      display: none;
    }
  }

  [class*="tile"] {
    border: 0.1px solid #d2d1d1;
    background: transparent;
    pointer-events: none;
    position: relative;
    display: flex;
    min-height: 220px;

    * {
      pointer-events: auto;
    }
    & > div {
      padding: 40px 4px;
    }
    abbr {
      border: 0.1px solid #d2d1d1;
      border-top: 0;
      border-right: 0;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 35px;
      line-height: 37px;
      background: #eaf7f9;
      cursor: default;
    }
    &:nth-child(7n + 1) {
      background-color: #fff9f8;
      color: #ca3030;
    }
  }

  [class*="weekday"] {
    border: 0.1px solid #d2d1d1;
    border-bottom: 0;
    text-align: center;
    background-color: #f3f3f3;
    height: 35px;
    abbr {
      cursor: default;
      text-decoration: none;
    }
    & > div:first-child {
      background-color: #ffe1d8;
    }
  }

  @media (max-width: 1498px) {
    overflow: auto;
  }
  @media (max-width: 1200px) {
    padding-left: 0;
    & > div {
      overflow: scroll;
      width: 1150px;
    }
  }
`

const CalanderListComponent: React.FC<iCalanderListComponent> = ({
  month,
  year,
  handleDisplayChange,
  participationData = null,
  serviceType,
}) => {
  const participationDataObject = {}

  if (participationData) {
    participationData?.data?.activity_participations?.map((item) => {
      participationDataObject[moment(item.date).format("YYYY-MM-DD")] = item
    })
  }
  return (
    <DataContainer>
      <FormTitle>
        <img
          style={{ verticalAlign: "top" }}
          src={"/assets/icons/schedule.svg"}
        />
        <span style={{ marginLeft: "7px" }}>
          {t("{{dateString}}の参加予定", {
            dateString: moment({ year: +year, month: +month - 1 }).format(
              "YYYY年MM月"
            ),
          })}
        </span>
      </FormTitle>
      <Divisor />
      <PaginationWrapper>
        <StatsWrapper>
          <Tag>
            {serviceType === 1 ? (
              <Tag style={{ background: theme.yellow1 }}>{t("放デ")}</Tag>
            ) : serviceType === 2 ? (
              <Tag style={{ background: theme.blue5 }}>{t("児発")}</Tag>
            ) : (
              "-"
            )}
          </Tag>
          <TextWrapper>
            <Label>{t("No. of current application:")}</Label>
            <Value>{`${participationData?.data?.applied_days}/${participationData?.data?.total_child_benifit_days}`}</Value>
          </TextWrapper>
          <TextWrapper>
            <Label>{t("Days of absence:")}</Label>
            <Value>{participationData?.data?.absent_days_count}</Value>
          </TextWrapper>
          <TextWrapper>
            <Label>{t("Day can be applied:")}</Label>
            <Value>
              {participationData?.data?.application_remaining_days_count}
            </Value>
          </TextWrapper>
        </StatsWrapper>
        <StyledMonthPaginationWrapper>
          <Button
            onClick={() => {
              let currentYear = year
              let currentMonth = month
              if (month == 1) {
                currentMonth = 12
                --currentYear
              } else {
                --currentMonth
              }
              handleDisplayChange({ year: currentYear, month: currentMonth })
            }}
            className={"left-btn"}
          >
            <DoubleLeftOutlined />
            <span className={"left-btn-label"}>{t("Last month")}</span>
          </Button>
          <Button
            className={"active active-btn"}
            onClick={() => {
              const today = new Date()
              const month = today.getMonth() + 1
              const year = today.getFullYear()
              handleDisplayChange({ year, month })
            }}
          >
            {t("This month")}
          </Button>
          <Button
            onClick={() => {
              let currentYear = year
              let currentMonth = month
              if (currentMonth == 12) {
                ++currentYear
                currentMonth = 1
              } else {
                ++currentMonth
              }
              handleDisplayChange({ year: currentYear, month: currentMonth })
            }}
            className={"right-btn"}
          >
            <span className={"right-btn-label"}>{t("Next month")}</span>
            <DoubleRightOutlined />
          </Button>
        </StyledMonthPaginationWrapper>
      </PaginationWrapper>
      <StyledCalendar
        tileContent={({ activeStartDate, date }) =>
          date.getMonth() === getMonth(activeStartDate) &&
          ![0, 6].includes(date.getDay()) &&
          participationDataObject[moment(date).format("YYYY-MM-DD")] &&
          participationDataObject[moment(date).format("YYYY-MM-DD")]
            ?.is_applied && (
            <CalandarCard
              currentDate={moment(date).format("YYYY-MM-DD")}
              calandarData={date.getDay()}
              participationData={participationDataObject}
            />
          )
        }
        formatDay={(_, date) => moment(date).format("DD")}
        showNavigation={false}
        locale={"ja"}
        calendarType={"US"}
        activeStartDate={new Date(year, month - 1, 1)}
      />
    </DataContainer>
  )
}

export { CalanderListComponent }
