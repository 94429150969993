import { theme } from "@project/shared"
import styled from "styled-components"

export const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 11px 30px 27px 30px;
  margin-top: 32px;
  * {
    font-size: 15px;
  }
  [class*="FormTitle"] {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .confirm-btn-wrapper {
    display: flex;
    justify-content: center;
    margin: 2.9rem 0 !important;
    button {
      padding: 0.6rem 1.8rem;
    }
  }
  .confirm-page-btn-wrapper {
    display: flex;
    justify-content: center;
    margin: 2.9rem 0 !important;
    flex-direction: column;
    div {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
    button {
      display: flex;
      div {
        margin-bottom: 0px;
      }
    }
  }
`

export const FormBody = styled.div`
  padding: 0 30px;
  small > span {
    font-size: 12px;
  }
  .answer-table {
    margin-top: 1.6rem;
  }
`
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;
`
export const CompletedContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: 32px;
  .complete-title {
    background: ${theme.orange3};
    color: #fff;
    padding: 8px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .complete-body {
    padding: 30px 10px;
    .cooperaion-block,
    .button-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .mb-25 {
      margin-bottom: 25px;
    }
  }
`
