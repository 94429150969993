import React, { useState } from "react"
import { CommentWrapper } from "./CommentsFrom.style"
import { useTranslation } from "react-i18next"
import { TextField } from "@project/shared"
import { notification } from "antd"
import { useFormik } from "formik"
import { useRouter } from "next/router"
import { useMutation } from "react-query"
import * as Yup from "yup"
import { addChat } from "../../../../../services"
import { ChildButton } from "../../../../atoms/ChildButton"

export const CommentsFrom = ({ isLoading, parentComment, date }: any) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [isEditing, setIsEditing] = useState(false)
  const initialValues = {
    parent_comment: parentComment || "",
    comment_id: +router?.query?.id,
    file_name: null,
    file_url: null,
    type: "text",
    date: date,
  }
  const { handleChange, handleSubmit, errors, values } = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      parent_comment: Yup.string().required(t("Required")),
    }),
    onSubmit: (values) => {
      mutate(values)
    },
  })
  const { mutate, isLoading: isCommenting } = useMutation(addChat, {
    onSuccess: () => {
      notification.success({
        message: `${t("Message")} ${t("Created Successfully")}`,
      })

      setIsEditing(false)
      router.push("/service-provision-record")
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator."),
      })
    },
  })

  return (
    <CommentWrapper>
      <div className={"text__title"}>{t("Comment")}</div>
      {parentComment && !isEditing ? (
        <div className={"parent__comment"}>
          {values?.parent_comment || parentComment}
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            value={values?.parent_comment || ""}
            type={"textarea"}
            rows={7}
            onChange={handleChange}
            name={"parent_comment"}
            disabled={isLoading}
            error={errors?.parent_comment || null}
            autoFocus
            onFocus={(e) => {
              const temp_value = e.target.value
              e.target.value = ""
              e.target.value = temp_value
            }}
          />

          <div className={"cta__btn"}>
            <div
              style={{
                display: "flex",
                gap: "0.4rem",
              }}
            >
              <ChildButton
                text={t("Comment")}
                type={"submit"}
                typeOf={"primary"}
                disabled={isCommenting || isLoading}
                isLoading={isCommenting}
              />
              {isEditing && (
                <ChildButton
                  text={t("Cancel")}
                  disabled={isCommenting || isLoading}
                  typeOf={"outlined"}
                  onClick={() => {
                    setIsEditing(false)
                  }}
                  shape={"circle"}
                />
              )}
            </div>
          </div>
        </form>
      )}
      <div className={"cta__btn"}>
        {parentComment && !isEditing ? (
          <ChildButton
            text={t("Edit comment")}
            typeOf={"primary"}
            onClick={() => setIsEditing(!isEditing)}
            type={"button"}
            icon={"edit"}
          />
        ) : null}
      </div>
    </CommentWrapper>
  )
}
