import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { StyledForm } from "../../../utils"
import styled from "styled-components"
import { Container, RadioButtons, Required, theme } from "@project/shared"
import { OwnerButton } from "../.."
import { useMutation, useQuery } from "react-query"
import {
  createParentAnswers,
  getParentAnswers,
  getSelfChildDetail,
} from "../../../services"
import { notification } from "antd"
import moment from "moment"

const ButtonWrapper = styled.div`
  margin-top: 40px;
  .btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
`
const ContentBox = styled.div`
  height: 120px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d2d1d1;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 30px;

  @media (max-width: 369px) {
    height: auto;
  }

  .concern-text {
    margin-top: 30px;
    @media (max-width: 767px) {
      margin-top: 0px;
      margin-left: 10px;
      word-break: break-all;
    }
  }

  .radio-btn {
    @media (max-width: 767px) {
      margin-left: 10px;
    }
  }
`

const RequiredWrapper = styled.span`
  margin-left: 15px;
`
export const ErrorCounterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 5px;
`

export const ErrorStyled = styled.span`
  font-size: 12px;
  color: ${theme.alert};
  margin-left: 2px;
  height: 20px;
`

const ParentEvaluationConfirmForm: React.FC = () => {
  const { t } = useTranslation()

  const currentYear = moment().format("YYYY")
  const { data: childData } = useQuery<any, Error>(
    ["fetch-self-child"],
    getSelfChildDetail,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) => {
        return {
          facility_id: data?.active_contract_facility?.facility_id,
          parent_id: data?.Parent?.id,
        }
      },
    }
  )
  const facilityID = childData?.facility_id
  const parentID = childData?.parent_id
  const { data: noticeData } = useQuery<any, Error>(
    ["fetch-self-child", parentID],
    () => getParentAnswers(parentID, currentYear, facilityID),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!parentID,
      retry: 1,
      select: ({ msg }) =>
        msg?.answers?.map((oneAnswer) => {
          return {
            facility_id: msg?.answers?.facility_id,
            questions: oneAnswer?.questionare?.question,
            questionare_id: oneAnswer?.questionare_id,
            opinion: oneAnswer?.opinion || "",
            possible_answers:
              oneAnswer?.questionare?.possible_answers?.map((answer, index) => {
                return {
                  label: answer,
                  value: index + 1,
                }
              }) || [],
          }
        }),
    }
  )

  const [answers, setAnswers] = useState({})
  const { isLoading: isSubmitLoading, mutate } = useMutation(
    createParentAnswers,
    {
      onSuccess: () => {
        notification.success({
          message: `${t("Evaluation form confirm submitted successfully")}`,
        })
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )
  const handleFormSubmit = (val) => {
    const data = {
      is_confirmed: val === 0 ? false : true,
      parentID: parentID,
      facilityID: facilityID,
      year: currentYear,
      parent_answers: noticeData?.map((v) => {
        return {
          questionare_id: v.questionare_id,
          answer: answers[v.questionare_id]?.toString(),
          opinion: v.opinion,
        }
      }),
    }
    mutate(data)
  }

  if (noticeData?.length === 0) {
    return null
  }
  return (
    <>
      <StyledForm onSubmit={handleFormSubmit} style={{ marginTop: "2rem" }}>
        {noticeData?.map((item, index) => (
          <>
            <Container className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t(`Q${index + 1}`)}</div>
              </div>
            </Container>
            <Container className={"flex-full grid-row-last"}>
              {item?.questions}
              <RequiredWrapper>
                <Required />
              </RequiredWrapper>
            </Container>
            <ContentBox>
              <RadioButtons
                className={"radio-btn"}
                name={item?.questionare_id}
                options={item?.possible_answers}
                value={answers[item?.questionare_id] || 1}
                onChange={(evt) => {
                  setAnswers((prev) => {
                    return {
                      ...prev,
                      [evt.target.name]: evt.target.value,
                    }
                  })
                }}
              />
              <span className={"concern-text"}>
                {"My concern is text text text text text"}
              </span>
            </ContentBox>
          </>
        ))}
      </StyledForm>
      <ButtonWrapper>
        <div className={"btns"}>
          <OwnerButton
            type={"submit"}
            typeOf={"primary"}
            text={t("Submit")}
            onClick={() => handleFormSubmit(0)}
            isLoading={isSubmitLoading}
          />
          <OwnerButton
            text={t("Correct")}
            typeOf={"secondary"}
            className={"cancel-btn"}
            type={"button"}
            onClick={() => handleFormSubmit(1)}
          />
        </div>
      </ButtonWrapper>
    </>
  )
}

export { ParentEvaluationConfirmForm }
