import { theme } from "@project/shared"
import { Card } from "../Card"
import styled from "styled-components"
import moment from "moment"
import { Collapse } from "antd"
import { DownOutlined } from "@ant-design/icons"

const { Panel } = Collapse

interface DateCardProps {
  title?: string
  content?: string
  expandTitle?: string
  expandText?: string
  station?: string
  dates?: Date[]
  borderColor?: string
  icon?: string
  isPanel?: boolean
}

const StyledCollapse = styled(Collapse)<{ borderColor?: string }>`
  @media screen and (max-width: 600px) {
    * {
      font-size: 12px;
    }
    & .ant-collapse-content-box {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
    }
  }
  .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    color: ${theme.red1};
    margin-top: 12px;
    text-decoration: underline;
  }
  .ant-collapse-item > .ant-collapse-content {
    padding: 0;
    margin-left: 1.8em;
    color: ${theme.red1};
  }
`

const StationDate = styled.div`
  border: 1px solid ${theme.gray2};
  display: grid;
  margin-top: 12px;
  grid-template-columns: 1fr 5fr;
  color: ${theme.black};
  * {
    grid-row: 1;
    padding: 10px 20px;
    font-size: 16px;
  }
  .station {
    border-right: 1px solid ${theme.gray2};
    background-color: ${theme.gray};
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 2fr 4fr;
    * {
      font-size: 12px;
    }
  }
`

export const DateCard = ({
  title,
  content,
  expandTitle,
  expandText,
  station,
  dates,
  icon,
  isPanel = true,
}: DateCardProps) => {
  return (
    <Card title={title} content={content} icon={icon}>
      <StyledCollapse
        className={"expand-title"}
        ghost
        expandIcon={() => (
          <DownOutlined
            style={{
              verticalAlign: "middle",
            }}
          />
        )}
      >
        {isPanel === false ? (
          ""
        ) : (
          <Panel header={expandTitle} key={1}>
            <p style={{ marginBottom: 0 }}>{expandText}</p>
            {/* Implement Station picker? */}
            {station ? (
              <StationDate>
                <div className={"station"}>{station}</div>
                {/* Implement datepicker */}
                {dates ? (
                  <div className={"datepicker"}>
                    {dates
                      .map((date) => moment(date).format("YYYY年MM月"))
                      .join(" ")}
                  </div>
                ) : (
                  ""
                )}
              </StationDate>
            ) : (
              ""
            )}
          </Panel>
        )}
      </StyledCollapse>
    </Card>
  )
}
