import { t } from "i18next"
import React from "react"
import styled, { css } from "styled-components"
import { theme } from "@project/shared"
import { Button, Props } from "../Button"

interface iButton extends Props {
  label?: string
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  className?: string
}

const StyledButton = styled(Button)<iButton>`
  padding: 12px 30px;
  ${({ typeOf }) =>
    typeOf === "link"
      ? css`
          color: ${theme.blue5};
          background: transparent;
          text-decoration: underline;
          font-weight: 400;
          font-size: 16px;
          &:hover {
            background: ${theme.gray2};
          }
        `
      : typeOf === "secondary"
      ? css`
          color: #191919;
          background-color: #f3f3f3;
          border: 1px solid #d2d1d1;
          &:hover {
            color: #191919;
            background-color: #f3f3f3;
            border: 1px solid #d2d1d1;
            opacity: 0.8;
          }
          &:disabled {
            cursor: not-allowed;
            background: #f3f3f3;
            color: #666161;
            &:hover {
              opacity: 1;
              background: #f3f3f3;
            }
          }
        `
      : typeOf === "draft"
      ? css`
          color: #191919;
          background: rgba(7, 130, 200, 0.2);
          border: 1px solid #0782c8;
          &:hover {
            color: #191919;
            background: rgba(7, 130, 200, 0.2);
            border: 1px solid #0782c8;
            opacity: 0.8;
          }
        `
      : typeOf === "check-all"
      ? css`
          border: 1px solid #d2d1d1;
          background: #ffffff;
          color: #000000;
          &:hover {
            border: 1px solid #d2d1d1;
            background: #ffffff;
            color: #000000;
            opacity: 0.8;
          }
        `
      : typeOf === "print"
      ? css`
          border: 1px solid #d2d1d1;
          background: grey;
          color: #ffffff;
          &:hover {
            border: 1px solid #d2d1d1;
            background: #ffffff;
            color: #000000;
            opacity: 0.8;
          }
        `
      : typeOf === "show"
      ? css`
          border: 1px solid #d2d1d1;
          color: #000000;
          &:hover {
            border: 1px solid #d2d1d1;
            background: #f3f3f3;
            color: #000000;
            opacity: 0.8;
          }
        `
      : typeOf === "outlined"
      ? css`
          border: 1px solid #d2d1d1;
          background: transparent;
          color: #000000;
          &:hover {
            border: 1px solid #d2d1d1;
            background: #ffffff;
            color: #000000;
            opacity: 0.8;
          }
        `
      : css`
          color: #f3f3f3;
          background-color: #0782c8;
          border-color: #0782c8;
          &:hover {
            color: #f3f3f3;
            background-color: #0782c8;
            border-color: #0782c8;
            opacity: 0.8;
          }
          &:disabled {
            cursor: not-allowed;
            background: #0782c8;
            opacity: 0.4;
          }
        `}
`

const OwnerButton: React.FC<iButton> = (props) => {
  const { typeOf, onClick, className, text } = props

  switch (typeOf) {
    case "search":
      return (
        <StyledButton
          onClick={onClick}
          className={"search-button"}
          text={text || "検索"}
          icon={"search"}
          shape={"circle"}
          {...props}
        />
      )

    case "primary":
      return (
        <StyledButton
          onClick={onClick}
          className={className}
          text={text}
          shape={"circle"}
          color={"#ffffff"}
          disabled={props.disabled}
          {...props}
        />
      )
    case "secondary":
      return (
        <StyledButton
          typeOf={"secondary"}
          onClick={onClick}
          className={className}
          text={text}
          shape={"circle"}
          disabled={props.disabled}
          {...props}
        />
      )

    case "copy":
      return (
        <Button
          text={t("Copy")}
          icon={"copy"}
          backgroundColor={"#858686 !important"}
          borderColor={"#858686 !important"}
          color={"white"}
          padding={5}
          className={"copy-btn"}
          {...props}
        />
      )
    case "draft":
      return (
        <StyledButton
          typeOf={"draft"}
          shape={"circle"}
          className={"draft-btn"}
          text={text || t("Save as Draft")}
          {...props}
        />
      )
    case "check-all":
      return (
        <StyledButton
          typeOf={"check-all"}
          icon={"check-all"}
          shape={"circle"}
          className={className}
          text={text || t("Check All")}
          {...props}
        />
      )
    case "outlined":
      return (
        <StyledButton
          typeOf={"outlined"}
          onClick={onClick}
          className={className}
          text={text}
          shape={"circle"}
          {...props}
        />
      )
    case "print":
      return (
        <StyledButton
          typeOf={"print"}
          onClick={onClick}
          className={className}
          text={text}
          icon={"print"}
          shape={"round"}
          {...props}
        />
      )
    case "pdf":
      return (
        <StyledButton
          typeOf={"pdf"}
          onClick={onClick}
          className={className}
          text={text}
          icon={"pdf"}
          shape={"circle"}
          {...props}
        />
      )
    case "show":
      return (
        <StyledButton
          typeOf={"show"}
          onClick={onClick}
          className={className}
          text={text}
          iconPosition={"right"}
          icon={"down"}
          shape={"round"}
          {...props}
        />
      )
    default:
      return <StyledButton text={text} typeOf={typeOf} {...props} />
  }
}

export { OwnerButton }
