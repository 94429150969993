import React from "react"
import { Wrapper } from "./EvaluationQuestion.styles"

interface iEvaluationAnswer {
  index: number
  question: string
  questionId: number | string
  answer?: any
  opinion?: any
}

export const EvaluationAnswer = ({
  index,
  question,
  questionId,
  answer = "",
  opinion = "",
}: iEvaluationAnswer) => {
  return (
    <Wrapper id={String(questionId)}>
      <div className={"question-box"}>
        <div className={"index"}>{`Q${index}`}</div>
        <div className={"question border-bottom"}>
          <div>{question}</div>
        </div>
      </div>
      <div className={"answers-box"}>
        {answer}
        <br />
        <div className={"opinion"}>{opinion}</div>
      </div>
    </Wrapper>
  )
}
